const initialState = {
	allAccounts: [],
	demoAccounts: [],
	liveAccounts: []
}

export const accountReducer = (state = initialState, action) => {
	const {type, payload} = action
	switch (type){
		case 'GET_ALL_ACCOUNTS': {
			return{
				...state,
				allAccounts: payload
			}
		}
		case 'GET_LIVE_ACCOUNTS': {
			return{
				...state,
				liveAccounts: payload
			}
		}
		case 'GET_DEMO_ACCOUNTS': {
			return {
				...state,
				demoAccounts: payload
			}
		}
		default:{
			return {
				...state
			}
		}
	}
}