import React, {useEffect, useState} from 'react'
import {Box, CircularProgress, Typography} from "@mui/material";
import {ContentBox} from "../../../../components/ContentBox";
import AppModal from "../../../../components/AppModal/AppModal";
import {useDispatch, useSelector} from "react-redux";
import {getBrandContact} from "../../../../store/reducers/details/actions";
import {selectBrandContact} from "../../../../store/reducers/details/selectors";
import {refreshToken2} from "../../../../store/reducers/auth/actions";
import { useTranslation } from "react-i18next";

const Deposit = () => {
	const dispatch = useDispatch()
	const { t, i18n } = useTranslation()
	const brandContact  = useSelector(selectBrandContact)
	const [contentLoading, setContentLoading] = useState(false)
	const [modalContent, setModalContent] = useState({
		title: '',
		text: ''
	})
	const [showModal, setShowModal] = useState(false)
	
	const fetchDetails = async () => {
		if(brandContact.brand_email) return
		try{
			setContentLoading(true)
			await dispatch(getBrandContact())
		} catch (error) {
			console.log('error', error)
		}
		finally {
			setContentLoading(false)
		}
	}
	
	useEffect(() => {
		fetchDetails()
	}, [])
	
	const handleCloseModal = () => {
		setShowModal(false)
	}

	return (
		<ContentBox>
			<Box textAlign={'center'} pt={'30px'} width={'100%'}>
				{contentLoading && (
					<Box textAlign={'center'} mt={5}>
						<CircularProgress/>
					</Box>
				)}
				{
					!contentLoading && brandContact.brand_email &&
					<>
						<Typography color={'white'} variant='h1' mb={'15px'}>
							{ t('paymentDetails') }
						</Typography>
						<Typography sx={{color: 'white'}}>
							{brandContact.brand_phone
								? `${t('performDeposit')} ${brandContact.brand_email} ${t('or')} ${brandContact.brand_phone}.`
								: `${t('performDeposit')} ${brandContact.brand_email}.`
							}
						</Typography>
					</>
				}
			</Box>
			<AppModal isOpen={showModal} title={modalContent.title} errorText={modalContent.text}
			          handleClose={handleCloseModal}/>
		</ContentBox>
	)
}

export default Deposit