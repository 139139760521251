import theme from "../../theme";

export const customStyles = {
  color: 'white',

  '&:not(.Mui-disabled):hover': {
    'fieldset': {
      borderColor: 'rgba(255,255,255, 0.7)'
    },
  },

  '& fieldset': {
    borderColor:' white'
  },

  '&.Mui-error fieldset': {
    borderColor: theme.palette.error.main
  },

  'svg': {
    fill: 'white'
  }
}