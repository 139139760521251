import React, { useState } from "react";
import {
	Box,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PageviewIcon from "@mui/icons-material/Pageview";
import DownloadIcon from "@mui/icons-material/Download";
import {
	getAvailableDocs,
	getDocumentsList,
} from "../../../../../store/reducers/kyc/actions";
import { useDispatch, useSelector } from "react-redux";
import { kycApi } from "../../../../../api/kyc";
import { selectKYCAvailableDocuments } from "../../../../../store/reducers/kyc/selectors";
import { truncateString } from "../../../../../utils";

const VerificationDocumentsTable = ({
	documents,
	modalController,
	setShowPreview,
}) => {
	const { t, i18n } = useTranslation();
	const docs = useSelector(selectKYCAvailableDocuments);
	const dispatch = useDispatch();

	const deleteKYCDocuments = async (data) => {
		modalController.quastion.hide();

		try {
			const token = sessionStorage.getItem("access_token");

			const res = await kycApi.deleteDoc(token, data);

			modalController.info.open(
				"File deletion success",
				"The file is successfully deleted",
				"OK"
			);
		} catch (error) {
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
					.then(async () => {
						await deleteKYCDocuments(data);
					})
					.catch((error) => console.log(error));
			} else {
				modalController.info.open(
					"File deletion error",
					<div>
						File deleting a file error accured<br></br>
						Error:{" "}
						{error.response.data.details ||
							error.response.data.detail ||
							error.message}
						<br></br>
						Please try once again<br></br>
					</div>,
					"OK"
				);
				console.log("error", error);
			}
		}
	};

	const deleteFile = (documentID) => {
		deleteKYCDocuments({
			crmid: documentID,
		}).then(() => {
			dispatch(getAvailableDocs());
			dispatch(getDocumentsList());
		});
	};

	const getFilenameFromURL = (url) => {
		const matches =
			/\/develop\/(?<filename>.{1,}\..{3,})\?X-Amz-Algorithm=/gi.exec(url);

		return matches?.groups?.filename;
	};

	return (
		<>
			<Box sx={{ color: "white" }}>
				<Typography>NOTE:</Typography>
				<Typography>You can upload:</Typography>
				{Object.entries(docs).map((item) => {
					const [name, quantity] = item;

					return (
						<Typography key={name}>
							- {quantity} {name} file{quantity > 1 || quantity == 0 ? "s" : ""}{" "}
							more
						</Typography>
					);
				})}
			</Box>
			<Box
				sx={{
					width: "100%",
					height: {
						md: "calc(100% - 85px)",
						xs: "calc(100% - 260px)",
						marginTop: "20px",
					},
				}}
			>
				<TableContainer sx={{ height: "100%", overflow: "auto" }}>
					<Table
						sx={{ minWidth: 540, paddingBottom: documents.length * 2.5 }}
						aria-label="withdraw-history-table"
						stickyHeader
					>
						<TableHead>
							<TableRow>
								<TableCell>{"Type"}</TableCell>
								<TableCell align="center">{"Upload date"}</TableCell>
								<TableCell align="center">{"Name"}</TableCell>
								<TableCell align="center">{"Preview"}</TableCell>
								<TableCell align="center">{"Delete"}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{documents
								? documents.map((item) => (
										<TableRow key={item.id}>
											<TableCell component="th" scope="row">
												{item.type}
											</TableCell>
											<TableCell align="center">{item.createdDate}</TableCell>
											<TableCell
												align="center"
												style={{
													maxWidth: "64px",
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
												}}
											>
												{getFilenameFromURL(item.url)}
											</TableCell>
											<TableCell align="center">
												<a
													href={URL.createObjectURL(item.fileData)}
													download={getFilenameFromURL(item.url)}
												>
													<DownloadIcon color="primary" />
												</a>
											</TableCell>
											<TableCell align="center">
												<IconButton
													aria-label="delete"
													color="primary"
													onClick={() =>
														modalController.quastion.open(
															"File deletion",
															`Are you sure you want to delete the file ${truncateString(
																getFilenameFromURL(item.url),
																17
															)}?`,
															() => deleteFile(item.id),
															"Delete",
															"Cancel"
														)
													}
												>
													<DeleteForeverIcon />
												</IconButton>
											</TableCell>
										</TableRow>
								  ))
								: ""}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</>
	);
};

export default VerificationDocumentsTable;
