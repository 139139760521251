import React from 'react'
import {Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {CURRENCY_SIGNS, WITHDRAW_STATUSES} from "../../../../../constants/constants";
import { useTranslation } from "react-i18next";
import { formatDate } from '../../../../../utils';

const WithdrawHistoryTable = ({transactionsList, onCancelWithdraw}) => {
	const { t, i18n } = useTranslation()
	
	return (
		<Box sx={{width: '100%', height: {md: 'calc(100% - 85px)', xs: 'calc(100% - 260px)'}, marginTop: '20px'}}>
			<TableContainer
				sx={{height: '100%', overflow: 'auto'}}
			>
				<Table sx={{minWidth: 540}} aria-label="withdraw-history-table" stickyHeader>
					<TableHead>
						<TableRow sx={{backgroundColor: '#F0F0F0'}}>
							<TableCell>{ t('requestDate') }</TableCell>
							<TableCell align="left">{ t('status') }</TableCell>
							<TableCell align="left">{ t('account') }</TableCell>
							<TableCell align="left">{ t('amount') }</TableCell>
							<TableCell align="left">{ t('action') }</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{transactionsList ? transactionsList.map((item) => (
							<TableRow
								key={item.transaction_id}
							>
								<TableCell component="th" scope="row">
									{formatDate(item.create_date)}
								</TableCell>
								<TableCell align="left">{t(WITHDRAW_STATUSES[item.transaction_approval])}</TableCell>
								<TableCell align="left">#{item.trading_account_id}</TableCell>
								<TableCell align="left">{CURRENCY_SIGNS[item.currency]}{item.amount}</TableCell>
								<TableCell align="left">{item.transaction_approval === 'p' ?
															<Button
																sx={{textTransform: 'unset'}}
																onClick={async (e) =>  await onCancelWithdraw(e,item.transaction_id, item.trading_account_id)}
															>{ t('cancel') }
															</Button> : ''
														}
								</TableCell>
							</TableRow>
						)) : ''}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

export default WithdrawHistoryTable