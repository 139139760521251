import React from 'react'
import {ActionBtn} from "./styled";

const ActionButton = ({title, sx, onClick, type, disabled, color, children}) => {

	return (
		<>
			<ActionBtn
				onClick={onClick}
				sx={sx}
				type={type}
				disabled={disabled}
				color={color}
				variant={'contained'}
			>
				{title}
				{children}
			</ActionBtn>
		</>
	)
}

export default ActionButton