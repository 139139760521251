import {Box, FormControl, InputLabel, OutlinedInput, styled} from "@mui/material";
import TextField from "@mui/material/TextField";

const TextFieldInput = styled(TextField)`
   & .MuiOutlinedInput-root {
       &:not(.Mui-disabled):hover {
           fieldset {
               border-color: rgba(255,255,255, 0.7);
           }
       }
       
       & fieldset {
            border-color: white;
       },
   
       &.Mui-focused {
            & fieldset {
                border-color: white;
            },
       }
   }

   & .MuiOutlinedInput-input {
       color: white;
       
       &.Mui-disabled {
           -webkit-text-fill-color: white;
           opacity: 0.5;
       }
   }
    
   & .MuiInputLabel-root {
       &.Mui-disabled {
           color: white;
           opacity: 0.5;
       }
        
       &.Mui-focused {
           color: white;
       }
   }
    
   & svg {
       fill: white;
   }
    
   .MuiTextField-root {
       color: white;
       font-size: 0.875rem;
       line-height: 1.5rem;
       letter-spacing: 0.009375rem;
       font-family: 'Roboto', sans-serif;
    }
   .MuiInputLabel-root {
       color: white;
       font-size: 0.875rem;
       line-height: 1.5rem;
       letter-spacing: 0.009375rem;
       font-family: 'Roboto', sans-serif;
   }
`

const UploadFileInput = styled(Box)`
	font: inherit;
	letter-spacing: inherit;
	color: currentColor;
	padding: 4px 0 5px;
	border: 0;
	box-sizing: content-box;
	background: none;
	height: 1.4375em;
	margin: 0;
	display: block;
	min-width: 0;
	width: 100%;
	animation-name: mui-auto-fill-cancel;
	animation-duration: 10ms;
	padding: 16.5px 14px;
	color: white;
	border-radius: 4px;
	border: 1px solid white;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: auto;
`

const SelectInputLabel = styled(InputLabel)`
  color: white;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
  font-family: 'Roboto', sans-serif;
`

const PasswordLabel = styled(InputLabel)`
  color: white;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.009375rem;
  font-family: 'Roboto', sans-serif;
  
`

const FormControlApp = styled(FormControl)`
  .MuiFormHelperText-root{
    font-family: 'Roboto', sans-serif;
    position: absolute;
    bottom: -25px;
    letter-spacing: .4px;
  }
`

export {TextFieldInput, SelectInputLabel, PasswordLabel, FormControlApp, UploadFileInput}