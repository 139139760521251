import {Button, styled} from "@mui/material";

const SidebarBox = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 260px;
  width: 260px;
  justify-content: space-between;
  height: 100%;
  z-index: 10;
  
  
  @media (max-width: 900px) {
  max-width: unset;
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	overflow: hidden;
    opacity: 0;
    transition-property: width, opacity ;
    transition-duration: .5s, .5s;
    transition-delay: .5s, 0s ;
    
	&.open {
	  opacity: 1;
	  width: 100%;
	  z-index: 3;
	  transition-property: width,opacity ;
	  transition-duration:  .3s, .3s;
	  transition-delay: 0s, 0.3s ;

	}
  }
  background-color: ${props => props.theme.palette.bgColors.color2};
  color: ${props => props.theme.palette.primary.contrastText};

  .MuiList-root {
	padding: unset;
    .MuiTypography-root {
	  font-family: 'Roboto-Medium', sans-serif;
	}
  }

  .MuiListItem-root {
	padding: unset
  }

  .MuiListItemIcon-root {
	min-width: 2.5rem;
  }

  .MuiListItemButton-root {
	padding-left: 1.5rem;
	padding-bottom: 16px;
	padding-top: 16px;
  }

  .MuiListItemText-root {
	font-family: 'Roboto-Medium', sans-serif;
  }
`

const DepositButton = styled(Button)`
  font-family: Roboto-Medium, sans-serif;
  border-radius: unset;
  background-color: ${props => props.theme.palette.bgColors.color4};
  width: 100%;
  color: ${props => props.theme.palette.primary.contrastText};
  font-size: 15px;
  line-height: 26px;
  padding: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  transition: .3s;

  &:hover, &.active {
	  opacity: 0.8;
    background-color: ${props => props.theme.palette.bgColors.color4};
  }
`

export {SidebarBox, DepositButton}