import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Box, CircularProgress, Grid, IconButton, InputLabel, MenuItem, Select} from "@mui/material";
import AppFormControl from "../../../../../components/AppForm/AppFormControl";
import AppFormFieldInput from "../../../../../components/AppForm/AppFormFieldInput";
import AppFormDatePicker from "../../../../../components/AppForm/AppFormDatePicker";
import ActionButton from "../../../../../components/ActionButton/ActionButton";
import {useForm} from "react-hook-form";
import AppModal from "../../../../../components/AppModal/AppModal";
import {clientDetails} from "../../../../../api/clientDetails";
import dayjs from "dayjs";
import AppFormSelectWithoutDefault from "../../../../../components/AppForm/AppFormSelectWithoutDefault";
import {useDispatch, useSelector} from "react-redux";
import {refreshToken2} from "../../../../../store/reducers/auth/actions";
import {
	changeClientBirthDate,
	changeClientDetails,
	getCitizenships,
	getClientDetails,
	getCountries
} from "../../../../../store/reducers/details/actions";
import {
	selectCitizenships,
	selectClientDetails,
	selectCountries
} from "../../../../../store/reducers/details/selectors";
import { useTranslation } from "react-i18next";

const AccountDetailsInfo = () => {
	const dispatch = useDispatch()
	const { t, i18n } = useTranslation()
	const citizenshipList = useSelector(selectCitizenships)
	const countriesList = useSelector(selectCountries)
	const  details = useSelector(selectClientDetails)
	const [loading, setLoading] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [modalContent, setModalContent] = useState({
		title: '',
		text: ''
	})
	
	const {register, handleSubmit, setValue} = useForm({
		mode: 'onChange',
		defaultValues: {
			clientDetails: {
				firstName: details.first_name,
				lastName: details.last_name,
				birthDate: details.birth_date ? dayjs(details.birth_date).format('MM/DD/YYYY') : null,
				country: details.country_code,
				city: details.city,
				address: details.address,
				citizenship: details.citizenship_code,
				zipcode: details.zip
			}
		}
	})
	
	const fetchCitizenshipList = async () => {
		if (citizenshipList.length) return
		try {
			await dispatch(getCitizenships())

		} catch (error) {
			console.log('error', error)
		}
	}

	const fetchCountriesList = async () => {
		if (countriesList.length) return
		try {
			await dispatch(getCountries())
		} catch (error) {
			console.log('error', error)
		}
	}
	const fetchClientDetails = async () => {
		if(Object.keys(details).length) return
		try{
			await dispatch(getClientDetails())
		}catch (error){
			console.log('error', error)
		}
	}
	const changeClientsProfile = async (data) => {
		try {
			const token = sessionStorage.getItem('access_token')
			const res = await clientDetails.updateClientsProfile( token, data)
		} catch (error) {
			if(error.response.status === 401){
				await dispatch(refreshToken2())
					.then(async () => {
						await changeClientsProfile(data)
					})
					.catch(error => console.log(error))
			} else {
				setShowModal(true)
				setModalContent({title: t('error'), text: error.response.data.details || error.response.data.detail || error.message})
				console.log('error', error)
			}
			
		}
	}

	const onSubmit = async () => {
		
		let formattedDate
		if(details.birth_date === 'Invalid Date'){
			setShowModal(true)
			setModalContent({title: t('error'), text: t('invalidBirthDate')})
			return
		} else{
			formattedDate = details.birth_date ? dayjs(details.birth_date).format('YYYY-MM-DD') : null
		}
		setLoading(true)
		const body = {
			birth_date: formattedDate,
			country_code: details.country_code || undefined,
			city: details.city || '',
			address: details.address || '',
			citizenship: details.citizenship_code || undefined,
			zip: details.zip || ''
		}
		changeClientsProfile(body)
			.then(() => dispatch(getClientDetails()))
			.finally(() => setLoading(false))
	}

	const handleClickForm = (event) => {
		event.stopPropagation()
	}
	
	const handleCloseModal = () => {
		setShowModal(false)
	}

	useEffect(() => {
		fetchCitizenshipList()
			.then(() => fetchCountriesList())
			.then(()=> fetchClientDetails())
	}, [])
	
	
	return (
		<Box
			component={'form'}
			onSubmit={handleSubmit(onSubmit)}
			onClick={handleClickForm}
		>
			<Box
				component={'fieldset'}
				border={'none'}
			>
					<Grid container columnSpacing={{md: 4}}>
						<Grid item xs={12} md={6}>
							<AppFormControl sx={{mb: '25px'}}>
								<AppFormFieldInput
									required={true}
									label={ t('firstName') }
									disabled={true}
									value={details.first_name || ''}
								/>
							</AppFormControl>

							<AppFormControl sx={{mb: '25px'}}>
								<AppFormFieldInput
									required={true}
									label={ t('lastName') }
									disabled={true}
									value={details.last_name || ''}
								/>
							</AppFormControl>

							<AppFormControl sx={{mb: '25px'}}>
								<AppFormDatePicker
									label={ t('birthDate') }
									/*{...register('clientDetails.birthDate')}*/
									date={details.birth_date ? dayjs(details.birth_date).format('MM/DD/YYYY') : null}
									onChange={(event) => dispatch(changeClientBirthDate(event))}
								/>
							</AppFormControl>

							<AppFormControl sx={{mb: '25px'}}>
								<AppFormSelectWithoutDefault
									label={ t('country') }
									onChange={(event) => dispatch(changeClientDetails( event,'country_code'))}
									value={details.country_code || ''}
								>
									{
										countriesList ?
											countriesList.map(item => (
												<MenuItem
													key={item.country_code}
													value={item.country_code}
													sx={{fontFamily: 'Roboto,sans-serif'}}
												>

													{item.country_name}
												</MenuItem>
											)) : ''
									}
								</AppFormSelectWithoutDefault>
							</AppFormControl>

							<AppFormControl sx={{mb: '25px'}}>
								<AppFormFieldInput
									label={ t('city') }
									value={details.city ? details.city : ''}
									onChange={(event) => dispatch(changeClientDetails( event,'city'))}
								/>
							</AppFormControl>

						</Grid>

						<Grid item xs={12} md={6}>
							<AppFormControl sx={{mb: '25px'}}>
								<AppFormFieldInput
									label={ t('address') }
									value={details.address ? details.address : ''}
									onChange={(event) => dispatch(changeClientDetails( event,'address'))}
								/>
							</AppFormControl>
							<AppFormControl sx={{mb: '25px'}}>
								<AppFormSelectWithoutDefault
									label={ t('citizenship') }
									value={details.citizenship_code || ''}
									onChange={(event) => dispatch(changeClientDetails( event,'citizenship_code'))}
								>
									{
										citizenshipList ?
											citizenshipList.map(item => (
												<MenuItem
													key={item.citizenship_code}
													value={item.citizenship_code}
													sx={{fontFamily: 'Roboto,sans-serif'}}
												>
													{item.citizenship_name}
												</MenuItem>
											)) : ''
									}
								</AppFormSelectWithoutDefault>
							</AppFormControl>

							<AppFormControl sx={{mb: '25px'}}>
								<AppFormFieldInput
									label={ t('zipCode') }
									value={details.zip ? details.zip : ''}
									onChange={(event) => dispatch(changeClientDetails( event,'zip'))}
								/>
							</AppFormControl>
						</Grid>

						<Grid item xs={12} md={6}>
							<ActionButton
								title={ t('saveChanges') }
								sx={{p: '10px'}}
								type={'submit'}
								disabled={loading}
							>
							{loading && (
								<CircularProgress
									size={28}
									sx={{
										color: 'primary',
										position: 'absolute',
										top: '50%',
										left: '50%',
										marginTop: '-12px',
										marginLeft: '-12px',
									}}
								/>
							)}
							</ActionButton>
						</Grid>
					</Grid>
			</Box>
			<AppModal
				isOpen={showModal}
				title={modalContent.title}
				errorText={modalContent.text}
				handleClose={handleCloseModal}
			/>
		</Box>
	)
}

export default AccountDetailsInfo