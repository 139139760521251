import {
	Box,
	CircularProgress,
	FormHelperText,
	Grid,
	MenuItem,
	Typography,
} from "@mui/material";
import imageCompression from "browser-image-compression";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ActionButton from "../../../../../components/ActionButton/ActionButton";
import AppFormControl from "../../../../../components/AppForm/AppFormControl";
import AppFormFieldInput from "../../../../../components/AppForm/AppFormFieldInput";
import AppFormFileUpload from "../../../../../components/AppForm/AppFormFileUpload";
import { UPLOAD_FILE_TYPE } from "../../../../../constants/constants";
import { v4 as uuidv4 } from "uuid";

import { getAvailableDocs } from "../../../../../store/reducers/kyc/actions";
import { selectKYCAvailableDocuments } from "../../../../../store/reducers/kyc/selectors";
import AppFormSelectWithoutDefault from "../../../../../components/AppForm/AppFormSelectWithoutDefault";
import { refreshToken2 } from "../../../../../store/reducers/auth/actions";
import { kycApi } from "../../../../../api/kyc";
import { getDocumentsList } from "../../../../../store/reducers/kyc/actions";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const BASE_FILE_UPLOAD_INPUT_TITLE = "Select file";
const MAX_UPLOAD_FILE_SIZE = 10_000_000;
const MB_SCALE = 0.000001;

const ACCEPTED_FILE_TYPES = [
	"image/heic",
	"image/heif",
	"image/jpeg",
	"image/png",
	"image/webp",
	"image/svg+xml",
	"application/pdf",
];

const getNewDocumentTemplate = () => ({
	fileComment: "",
	fileType: "",
	filename: BASE_FILE_UPLOAD_INPUT_TITLE,
	file: null,
	inputUUID: uuidv4(),
});

const VerificationUploadFileForm = ({ hideUploadForm, modalController }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const docs = useSelector(selectKYCAvailableDocuments);
	const [uploadedDocuments, setUploadedDocuments] = useState([
		getNewDocumentTemplate(),
	]);
	const [loading, setLoading] = useState(false);
	const {
		register,
		reset,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm({
		mode: "all",
	});

	const sendKYCDocuments = async (data) => {
		try {
			const token = sessionStorage.getItem("access_token");
			const res = await kycApi.uploadDocs(token, data);

			resetForm();
			hideUploadForm();

			modalController.info.open(
				"File upload success",
				`The ${uploadedDocuments.length <= 1 ? "file" : "files"} ${
					uploadedDocuments.length <= 1 ? "was" : "were"
				} uploaded successfully`,
				"OK"
			);
		} catch (error) {
			console.log(error);
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
					.then(async () => {
						await sendKYCDocuments(data);
					})
					.catch((error) => console.log(error));
			} else {
				modalController.info.open(
					"File upload error",
					<>
						<Typography>
							{`While uploading ${
								uploadedDocuments.length <= 1 ? "file" : "files"
							} an error occured`}
						</Typography>
						<Typography>
							{`Error: ${
								error.response.data.details ||
								error.response.data.detail ||
								error.message
							}`}
						</Typography>
						<Typography>Please try once again</Typography>
					</>
				);
				console.log("error", error);
			}
		}
	};

	const resetForm = () => {
		reset();
		setUploadedDocuments(() => [getNewDocumentTemplate()]);
	};

	const onSubmit = async () => {
		setLoading(true);

		const formData = new FormData();

		uploadedDocuments.forEach((doc, i) => {
			formData.append(`type${i >= 1 ? i + 1 : ""}`, doc.fileType);
			formData.append(`note${i >= 1 ? i + 1 : ""}`, doc.fileComment);
			formData.append(`file${i >= 1 ? i + 1 : ""}`, doc.file);
		});

		sendKYCDocuments(formData)
			.then(() => {
				dispatch(getAvailableDocs());
				dispatch(getDocumentsList());
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleClickForm = (event) => {
		event.stopPropagation();
	};

	const handleUploadFile = async (event, documentIndex) => {
		let rescaledFile = event.target.files[0];
		let fileType = event.target.files[0].type;

		if (/\.heic/gi.test(event.target.files[0].name)) {
			fileType = "image/heic";
		} else if (/\.heif/gi.test(event.target.files[0].name)) {
			fileType = "image/heif";
		}

		if (
			fileType != "application/pdf" &&
			fileType != "image/heif" &&
			fileType != "image/heic" &&
			validateFile(event.target.files[0]) === true
		) {
			const compressedFileBlob = await imageCompression(event.target.files[0], {
				maxSizeMB: Math.floor(MAX_UPLOAD_FILE_SIZE * MB_SCALE),
				maxWidthOrHeight: 1024,
				useWebWorker: true,
			});

			rescaledFile = new File(
				[compressedFileBlob],
				event.target.files[0].name,
				{
					lastModified: new Date(),
					type: fileType,
				}
			);
		}

		setUploadedDocuments((oldState) => {
			const items = [...oldState];
			items[documentIndex].filename = event.target.files[0].name;
			items[documentIndex].file = rescaledFile;
			return items;
		});
	};

	const handleResetUploadedFile = (event, documentIndex) => {
		event.target.value = null;
		event.target.type = null;

		setUploadedDocuments((oldState) => {
			const items = [...oldState];
			items[documentIndex].filename = BASE_FILE_UPLOAD_INPUT_TITLE;
			items[documentIndex].file = null;
			return items;
		});

		reset();
	};

	const validateFile = (document) => {
		if (!document.file) return "File not selected";

		let fileType = document.file.type;

		if (document.file.size > MAX_UPLOAD_FILE_SIZE)
			return `Not allowed file size. Allowed size ${Math.floor(
				MAX_UPLOAD_FILE_SIZE * MB_SCALE
			)}MB`;

		if (/\.heic/gi.test(document.file.name)) {
			fileType = "image/heic";
		} else if (/\.heif/gi.test(document.file.name)) {
			fileType = "image/heif";
		}

		if (!ACCEPTED_FILE_TYPES.includes(fileType))
			return `Not allowed file type. Allowed types: ${ACCEPTED_FILE_TYPES.join(
				", "
			).replaceAll(/application\/|image\//gi, ".")}`;

		return true;
	};

	const handleAddDocumentComment = (value, documentIndex) => {
		if (value.length > 255) return;

		setUploadedDocuments((state) => {
			const items = [...state];
			items[documentIndex].fileComment = value;
			return items;
		});
	};

	const handleAddDocument = () => {
		setUploadedDocuments((state) => [...state, getNewDocumentTemplate()]);
		dispatch(getAvailableDocs());
	};

	const handleDeleteDocument = (documentIndex) => {
		if (uploadedDocuments.length <= 1) return;
		reset();
		setUploadedDocuments((state) => {
			const items = [...state];
			items.splice(documentIndex, 1);
			return items;
		});
		dispatch(getAvailableDocs());
	};

	const handleTypeDocument = (value, documentIndex) => {
		setUploadedDocuments((state) => {
			const items = [...state];
			items[documentIndex].fileType = value;
			return items;
		});
		setValue(`fileType_${uploadedDocuments[documentIndex].inputUUID}`, value);
	};

	const checkUploadCapabilityByType = (option) => {
		const documents = uploadedDocuments.filter(
			(doc) => doc.fileType === option.value
		);

		return !(documents.length < docs[option.title]);
	};

	const disableAddNewDocument = () => {
		const docsTypesAvalible = [
			...new Set(
				UPLOAD_FILE_TYPE.map((option) => checkUploadCapabilityByType(option))
			),
		];

		if (docsTypesAvalible.length > 1) return false;

		return docsTypesAvalible[0];
	};

	const preValidateForm = () => {
		let isValid = true;

		for (const document of uploadedDocuments) {
			if (!document.fileType.length || document.file == null) {
				isValid = false;
				break;
			}
		}

		return isValid;
	};

	return (
		<Box
			component={"form"}
			onSubmit={handleSubmit(onSubmit)}
			onClick={handleClickForm}
		>
			{loading && (
				<Box
					style={{
						top: 0,
						left: 0,
						position: "absolute",
						width: "100%",
						height: "100%",
						background: "#00000075",
						zIndex: "22",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CircularProgress
						size={28}
						sx={{
							color: "primary",
							position: "absolute",
							top: "50%",
							left: "50%",
							marginTop: "-12px",
							marginLeft: "-12px",
						}}
					/>
					<Typography style={{ color: "white", marginTop: "80px" }}>
						Uploading...
					</Typography>
				</Box>
			)}
			<Box sx={{ color: "white" }}>
				<Typography>NOTE:</Typography>
				<Typography>You can upload:</Typography>
				{Object.entries(docs).map((item) => {
					const [name, quantity] = item;

					return (
						<Typography key={name}>
							- {quantity} {name} file{quantity > 1 || quantity == 0 ? "s" : ""}{" "}
							more
						</Typography>
					);
				})}
			</Box>
			<Box>
				<Grid>
					{uploadedDocuments.length &&
						uploadedDocuments.map((document, i) => (
							<Box
								key={document.inputUUID}
								sx={{
									width: "100%",
									marginTop: "30px",
								}}
							>
								<Grid
									container
									rowSpacing={3}
									columns={12}
									sx={{ justifyContent: "space-between", marginBottom: 2 }}
								>
									<Grid item xs={12} md={12} lg={3}>
										<AppFormControl>
											<AppFormSelectWithoutDefault
												label={"File Type"}
												{...register(`fileType_${document.inputUUID}`, {
													required: "Type need to select",
												})}
												onChange={({ target: { value } }) =>
													handleTypeDocument(value, i)
												}
												value={document.fileType}
												isValid={document.fileType == ""}
											>
												{UPLOAD_FILE_TYPE.map((option) => (
													<MenuItem
														key={option.value}
														value={option.value}
														sx={{ fontFamily: "Roboto,sans-serif" }}
														disabled={checkUploadCapabilityByType(option)}
													>
														{option.title}
													</MenuItem>
												))}
											</AppFormSelectWithoutDefault>
											{document.fileType == "" && (
												<FormHelperText error>
													Type need to select
												</FormHelperText>
											)}
										</AppFormControl>
									</Grid>
									<Grid item xs={12} md={12} lg={3}>
										<AppFormControl sx={{ flexDirection: "column" }}>
											<AppFormFileUpload
												required={true}
												label={"Upload File"}
												filename={document.filename}
												handleUploadFile={() => handleUploadFile(event, i)}
												isValid={validateFile(document)}
												handleResetUploadedFile={(event) =>
													handleResetUploadedFile(event, i)
												}
												{...register(`uploadFileInput_${document.inputUUID}`, {
													validate: () => validateFile(document),
												})}
											/>
											{validateFile(document) && (
												<FormHelperText error style={{ position: "unset" }}>
													{validateFile(document)}
												</FormHelperText>
											)}
										</AppFormControl>
									</Grid>
									<Grid item xs={12} md={12} lg={3}>
										<AppFormControl>
											<AppFormFieldInput
												label={"File comment"}
												value={document.fileComment}
												onChange={({ target: { value } }) =>
													handleAddDocumentComment(value, i)
												}
											/>
										</AppFormControl>
									</Grid>
									<Grid item xs={12} md={12} lg={2}>
										<AppFormControl sx={{ height: "100%" }}>
											<ActionButton
												title={"Delete Row"}
												color={"primary"}
												onClick={() => handleDeleteDocument(i)}
												sx={{
													p: "11px 40px",
													width: "auto",
													height: "100%",
													lineHeight: 1,
													maxHeight: "56px",
												}}
											/>
										</AppFormControl>
									</Grid>
								</Grid>
							</Box>
						))}

					<Box sx={{ width: "100%", marginTop: 3 }}>
						<ActionButton
							title={"+ add one more file"}
							color={"primary"}
							sx={{ width: "auto", marginBottom: 6 }}
							disabled={disableAddNewDocument()}
							onClick={() => handleAddDocument()}
						/>

						<Box display={"flex"} gap={"8px"} justifyContent={"end"}>
							<ActionButton
								title={t("cancel")}
								color={"primary"}
								onClick={() => hideUploadForm()}
								sx={{ p: "11px 40px", width: "auto" }}
							/>
							<ActionButton
								title={"Submit"}
								color={"secondary"}
								sx={{ p: "11px 40px", width: "auto" }}
								disabled={!preValidateForm() || loading}
								type={"submit"}
							></ActionButton>
						</Box>
					</Box>
				</Grid>
			</Box>
		</Box>
	);
};

export default VerificationUploadFileForm;
