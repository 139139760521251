import React, {useState} from 'react'
import {ContentBox} from "../../../../components/ContentBox";
import {
	MANAGE_ACCOUNTS_DEMO_ID,
	MANAGE_ACCOUNTS_LIVE_ID, MANAGE_ACCOUNTS_OPEN_ACCOUNT_FORM,
	MANAGE_ACCOUNTS_TRANSFER_FORM
} from "../../../../constants/constants";
import ManageAccountsLive from "./components/ManageAccountsLive";
import ManageAccountsDemo from "./components/ManageAccountsDemo";
import ManageAccountsNav from "./components/ManageAccountsNav";
import {Box} from "@mui/material";
import ManageAccountsOpenAccountForm from "./components/ManageAccountsOpenAccountForm";
import ManageAccountsTransferForm from "./components/ManageAccountsTransferForm";
import { useTranslation } from "react-i18next";

const ManageAccounts = () => {
	const { t, i18n } = useTranslation()
	const [activeTab, setActiveTab] = useState(MANAGE_ACCOUNTS_LIVE_ID)
	const [activeForm, setActiveForm] = useState('')
	const [showForm, setShowForm] = useState(false)
	
	const handleClickTab = (id) => {
		setActiveTab(id)
	}

	const handleShowForm = (id) => {
		setActiveForm(id)
		setShowForm(true)
	}
	
	const handleHideForm = (tabId) => {
		setShowForm(false)
		setActiveTab(tabId)
	}

	const activeTabDisplay = (activeTab) => {
		switch (activeTab) {
			case MANAGE_ACCOUNTS_LIVE_ID:
				return <ManageAccountsLive openForm={handleShowForm}/>
			case MANAGE_ACCOUNTS_DEMO_ID:
				return <ManageAccountsDemo openForm={handleShowForm}/>
			default:
				return <ManageAccountsLive/>
		}
	}
	
	const activeFormDisplay = (activeForm) => {
		switch (activeForm) {
			case MANAGE_ACCOUNTS_TRANSFER_FORM:
				return <ManageAccountsTransferForm closeForm={() => handleHideForm(activeTab)} />
			case MANAGE_ACCOUNTS_OPEN_ACCOUNT_FORM:
				return <ManageAccountsOpenAccountForm closeForm={() => handleHideForm(activeTab)}/>
			default:
				return <Box>{ t('transferForm') }</Box>
		}
	}
	
	return (
		<>
			{ !showForm && 
				<ContentBox sx={{
				flexDirection: 'column',
				overflowX: 'hidden',
				overflowY: activeTab === MANAGE_ACCOUNTS_LIVE_ID ? 'hidden' : ''
			}}>
				<ManageAccountsNav handleClickTab={handleClickTab} openedTab={activeTab}/>
				{activeTabDisplay(activeTab)}
			</ContentBox>}
			{ showForm &&
				<ContentBox sx={{
					flexDirection: 'column',
					pl: '20px',
					pr: '20px'
				}}>
					{activeFormDisplay(activeForm)}
				</ContentBox>
			}
		</>
	)
}

export default ManageAccounts