import {Box, styled} from "@mui/material";

const ContentBox = styled(Box)`
  display: flex;

  padding-top: 25px;
  padding-left: 65px;
  padding-right: 80px;
  max-width: 1180px;
  width: 100%;
@media(max-width: 576px){
  padding-left: 16px;
  padding-right: 16px;
}
`

export { ContentBox }