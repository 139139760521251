import instance from "./index";

export const authApi = {

	login: async (crm_token) => {
		return instance.post(`${process.env.REACT_APP_URL}/api/auth/token/`,
			{
				client_area_id: process.env.REACT_APP_CLIENT_AREA_ID,
				client_area_secret: process.env.REACT_APP_CLIENT_AREA_SECRET,
				grant_type: 'crm_token',
				crm_token: crm_token
			},
			{
				transformRequest: (data, headers) => {
					delete headers.authorization
					return JSON.stringify(data)
				}
			}
		)
	},

	refreshToken: async (refreshToken) => {
		return instance.post(`${process.env.REACT_APP_URL}/api/auth/token/`,
			{
				client_area_id: process.env.REACT_APP_CLIENT_AREA_ID,
				client_area_secret: process.env.REACT_APP_CLIENT_AREA_SECRET,
				grant_type: 'refresh_token',
				refresh_token: refreshToken
			},
			{
				transformRequest: (data, headers) => {
					delete headers.authorization
					return JSON.stringify(data)
				}
			}
		)
	},

	logout: async (token) => {
		return instance.get('/logout/', {
			headers: {
				authorization: `Bearer ${token}`,
			}
		})
	}
}