import React from 'react'
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {CURRENCY_SIGNS, TRANSACTION_TYPES} from "../../../../../constants/constants";
import { useTranslation } from "react-i18next";
import { formatDate } from '../../../../../utils';

const TransactionHistoryTable = ({transactions}) => {
	const { t, i18n } = useTranslation()

	return(
		<Box mt={'20px'} sx={{width: '100%', height: {md:'calc(100% - 85px)', xs:'calc(100% - 260px)', marginTop:'20px'}}}>
			<TableContainer
				sx={{ height: '100%', overflow: 'auto'}}
			>
				<Table
					sx={{minWidth: 540}} aria-label="withdraw-history-table" stickyHeader 
				>
					<TableHead >
						<TableRow>
							<TableCell>{ t('date') }</TableCell>
							<TableCell align="left">{ t('type') }</TableCell>
							<TableCell align="left">{ t('account') }</TableCell>
							<TableCell align="left">{ t('amount') }</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{transactions ? transactions.map((item) => (
							<TableRow
								key={item.transaction_id}
							>
								<TableCell component="th" scope="row">
									{formatDate(item.create_date)}
								</TableCell>
								<TableCell align="left">{t(TRANSACTION_TYPES[item.transaction_type])}</TableCell>
								<TableCell align="left">#{item.trading_account_id}</TableCell>
								<TableCell align="left">{CURRENCY_SIGNS[item.currency]}{item.amount}</TableCell>
							</TableRow>
						)) : ''}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}
export default TransactionHistoryTable