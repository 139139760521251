export const defaultTheme = {
	typography: {
		fontFamily: `Roboto`,
		fontSize: 14,

		h1: {
			fontFamily: "Roboto-Bold, sans-serif",
			fontSize: "1.5rem",
			color: "#FFFFFF",
		},
		h2: {
			fontFamily: "Roboto-Medium, sans-serif",
			fontSize: "1.25rem",
			color: "#FFFFFF",
		},
		h3: {
			fontFamily: "Roboto-Medium, sans-serif",
			color: "#FFFFFF",
		},
		h4: {
			fontFamily: "Roboto-Medium, sans-serif",
			fontSize: "2rem",
			color: "#FFFFFF",
		},
		h5: {
			fontFamily: "Roboto-Medium, sans-serif",
			color: "#FFFFFF",
		},
		h6: {
			fontFamily: "Roboto-Medium, sans-serif",
			color: "#FFFFFF",
		},
		subtitle1: {
			fontFamily: "Roboto-Medium, sans-serif",
			fontSize: "1rem",
			color: "#FFFFFF",
		},
		subtitle2: {
			fontFamily: "Roboto-Medium, sans-serif",
			fontSize: "0.875rem",
			color: "#FFFFFF",
		},
		body2: {
			fontFamily: "Roboto, sans-serif",
			fontSize: "0.875rem",
			lineHeight: "1.625rem",
			color: "white",
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1280,
			lgx: 1367,
			xl: 1536,
		},
	},
	palette: {
		bgColors: {
			color: "rgba(0,0,0,.58)",
			color2: "#182831",
			color3: "rgba(0,0,0,.25)",
			color4: "rgba(42,174,174,.8)",
			headerBg: "rgb(13, 14, 14)",
			dashBoardBg: "rgb(73, 97, 102)",
		},
		primary: {
			light: "#4AD381",
			main: "#FFFFFF",
			dark: "#3981E4",
			contrastText: "#ffffff",
		},
		secondary: {
			light: "#f5f6f7",
			main: "#4AD381",
			dark: "#4AD381",
			contrastText: "#175664",
		},
		error: {
			light: "#fff7f7",
			main: "#FF4500",
			dark: "#863030",
		},
		text: {
			primary: "#000000",
			secondary: "#3981E4",
		},
		action: {
			disabledBackground: "rgba(0,0,0,0.05)",
			disabled: "#afafaf",
		},
		info: {
			light: "#5b646b",
			main: "#deeaed",
			dark: "#0e4753",
			contrastText: "#ffffff",
		},
	},
	components: {
		MuiPickersFadeTransitionGroup: {
			styleOverrides: {
				root: {
					"& .MuiDayPicker-weekDayLabel": {
						color: "rgba(42,174,174,.8)",
					},
				},
			},
		},
		PrivatePickersYear: {
			styleOverrides: {
				root: {
					"& .PrivatePickersYear-yearButton": {
						color: "rgba(42,174,174,.8)",

						"&.Mui-selected": {
							backgroundColor: "rgba(42,174,174,.8) !important",
						},

						"&.Mui-disabled": {
							color: "rgba(42,174,174,.4)",
						},
					},
				},
			},
		},
		MuiPickersDay: {
			styleOverrides: {
				root: {
					"&.Mui-selected": {
						backgroundColor: "rgba(42,174,174,.8) !important",
					},
					"&.MuiPickersDay-today": {
						borderColor: "rgba(42,174,174,.8) !important",
					},
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					backgroundColor: "#F0F0F0",
					textTransform: "uppercase",
				},
				stickyHeader: {
					backgroundColor: "red",
					textTransform: "uppercase",
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					color: "white",
				},
				head: {
					color: "black",
					fontFamily: "Roboto-Medium, san-serif",
					backgroundColor: "#F0F0F0",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					color: "white",
					fontFamily: "Roboto-Medium, san-serif",
				},
			},
		},
		MuiTableContainer: {
			styleOverrides: {
				root: {
					"&::-webkit-scrollbar": {
						width: 0,
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
						{
							display: "none",
						},
					"& input[type=number]": {
						MozAppearance: "textfield",
					},
				},
			},
		},
	},
};
