import instance from "./index";

export const manageAccountsApi = {
	getCurrenciesTypes: async (token) => {
		return instance.get('/client/currencies/list',
			{
				headers:{
					authorization: `Bearer ${token}`
				}
			})
	},

	getAccountTypes: async (token) => {
		return instance.get('/transaction/account_types/list/',
			{
				headers:{
					authorization: `Bearer ${token}`
				}
			})
	},

	createClientsTradingAccount: async (token, accountType, currency) => {
		return instance.post('/client/trading_account/create/',
			{
				account_type: accountType,
				currency: currency
			},
			{
				headers:{
					authorization: `Bearer ${token}`
				}
			}
		)
	},

	createTransferBetweenAccounts: async (token, data) => {
		return instance.post('/transaction/transfer/create/', data,
			{
				headers:{
					authorization: `Bearer ${token}`
				}
			})
	}
}