import instance from "./index";


export const clientDetails = {

	getCountries: async (token) => {
		return instance.get('/country/list/?limit=300',
			{
				headers:{
					authorization: `Bearer ${token}`
				}
			})
	},

	getCitizenshipList: async (token) => {
		return instance.get('/citizenship/list/',
			{
				headers:{
					authorization: `Bearer ${token}`
				}
			})
	},
	
	getClientsProfile: async (token) => {
		return instance.get('/client/account/',
			{
				headers:{
					authorization: `Bearer ${token}`
				}
			})
	},
	
	updateClientsProfile: async(token, data) => {
		return instance.post('/client/account/update',data,
			{
				headers:{
					authorization: `Bearer ${token}`
				}
			})
	},

	changePassword: async (token, data) => {
		return instance.post('/client/forgot_password/change_password/', data,
			{
				headers:{
					authorization: `Bearer ${token}`
				}
			})
	}
}