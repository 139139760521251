/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
	return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return mergeDeep(target, ...sources);
}

export function formatDate(initialDate) {
	const dt = new Date(initialDate);
	const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);

	return `${padL(dt.getDate())}.${padL(
		dt.getMonth() + 1
	)}.${dt.getFullYear()} ${padL(dt.getHours())}:${padL(dt.getMinutes())}`;
}

export function truncateString(s, w) {
	return s.length > w ? s.slice(0, w) + "..." : s;
}
