import {Button, styled} from "@mui/material";

const ActionBtn = styled(Button)`
  font-family: Roboto-Medium, sans-serif;
  position: relative;
  width: 100%;
  color: ${props => props.theme.palette.primary.contrastText};
  background-color: ${props => props.theme.palette.bgColors.color4};
  font-size: 15px;
  line-height: 26px;
  padding: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-direction: row;
  transition: .3s;
  border-radius: 5px;
  box-shadow: unset !important;
    
  &.MuiButton-containedSecondary {
      background-color: white;
      color: black;
  }  
    
  &:hover {
      background-color: ${props => props.theme.palette.bgColors.color4};
      opacity: 0.8;
      
      &.MuiButton-containedSecondary {
          background-color: white;
      }
  }  
  
  &.Mui-disabled{
    background-color: ${props => props.theme.palette.bgColors.color4};
    opacity: 0.3;
    color: ${props => props.theme.palette.primary.contrastText };
      
    &.MuiButton-containedSecondary {
        background-color: white;
        color: black;
    }
  }
`
export {ActionBtn}