const initialState = {
	documents: [],
	getAvailableDocuments: {},
};

export const kycReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case "GET_AVAILABLE_DOCS": {
			return {
				...state,
				getAvailableDocuments: payload,
			};
		}
		case "GET_DOCUMENTS_LIST": {
			return {
				...state,
				documents: payload?.details ? payload : [...payload],
			};
		}
		case "DELETE_DOCUMENT": {
			return {
				...state,
				documents: { ...payload },
			};
		}
		default: {
			return { ...state };
		}
	}
};
