import {Accordion, styled} from "@mui/material";


const WebTraderAccordion = styled(Accordion)`
  height: 100%;
  border-radius: unset;
  border: unset;
	background-color: unset;
  box-shadow: unset;
  .MuiAccordionSummary-root{
    color: ${props => props.theme.palette.primary.contrastText};
    background-color: transparent;
    font-family: 'Roboto-Medium',sans-serif;
    font-weight: 500;
    padding-left: 1.5rem;
    .Mui-expanded{
      margin: 12px 0;
    }
	&.Mui-expanded{
	  min-height: unset;
	}
  }

  .MuiAccordionDetails-root{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 60px;
    padding-right: 40px;
    background-color: ${props=> props.theme.palette.bgColors.color3};
    color: ${props=> props.theme.palette.primary.main};
    height: 100%;   
    overflow: auto;

  }
  
  .MuiCollapse-entered{
	height: calc(100% - 48px) !important;
    overflow: auto;
  }
`
export {WebTraderAccordion} 