import i18n from "../i18n"

/**
 * IDs for dashboard
 */
export const TRANSACTION_HISTORY_ID = '1'
export const ACCOUNT_DETAILS_ID = '2'
export const WITHDRAW_ID = '3'
export const VERIFICATION_ID = '4'
export const MANAGE_ACCOUNTS_ID = '5'
export const DEPOSIT_ID = '6'
export const WEB_TRADER_ID = '7'

/**
 * IDs for account details tab
 */
export const ACCOUNT_DETAILS_INFO_ID = '1'
export const ACCOUNT_DETAILS_PASSWORD_ID = '2'

/**
 * IDs for withdraw tabs
 */
export const WITHDRAW_REQUEST_ID = '1'
export const WITHDRAW_HISTORY_ID = '2'

/**
 * IDs for manage accounts tabs
 */
export const MANAGE_ACCOUNTS_LIVE_ID = '1'
export const MANAGE_ACCOUNTS_DEMO_ID = '2'

/**
 * IDs for forms in manage accounts tab
 */
export const MANAGE_ACCOUNTS_TRANSFER_FORM = '1'
export const MANAGE_ACCOUNTS_OPEN_ACCOUNT_FORM = '2'


export const REGEX_PASSWORD_VALIDATION = {
	value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
	message: 'wrongPasswordFormat'
}

export const PERIOD_FILTER_VALUES = [
	{
		title: 'allPeriod',
		value: ''
	},
	{
		title: 'today',
		value: 'today'
	},
	{
		title: 'last3days',
		value: 'last_3_days'
	},
	{
		title: 'lastWeek',
		value: 'last_week'
	},
	{
		title: 'lastMonth',
		value: 'last_month'
	},
	{
		title: 'last3month',
		value: 'last_3_month'
	}
]
export const TRANSACTION_TYPE_FILTER_VALUES = [
	{
		title: 'all',
		value:''
	},
	{
		title: 'deposit',
		value:'d'
	},
	{
		title: 'withdrawal',
		value:'w'
	},
	{
		title: 'creditIn',
		value:'ci'
	},
	{
		title: 'creditOut',
		value:'co'
	},
]
export const WITHDRAW_TRANSACTIONS_STATUSES = [
	{
		title: 'all',
		value: ''
	},
	{
		title: 'pending',
		value: 'pending'
	},
	{
		title: 'approved',
		value: 'approved'
	},
	{
		title: 'cancelled',
		value: 'cancelled'
	},
	{
		title: 'rejected',
		value: 'rejected'
	},
];

export const UPLOAD_FILE_TYPE = [
	{
		title: "File Type",
		value: "",
	},
	{
		title: "Proof of Identity",
		value: "poi",
	},
	{
		title: "Proof of Residence",
		value: "por",
	},
	{
		title: "Proof of Payment",
		value: "pop",
	},
	{
		title: "Other",
		value: "other",
	},
];

export const CURRENCY_SIGNS = {
	'EUR': '€',
	'USD': '$',
	'GBP': '£'
}

export const TRANSACTION_TYPES ={
	'd': 'deposit',
	'w': 'withdrawal',
	'ci': 'creditIn',
	'co': 'creditOut'
}

export const WITHDRAW_STATUSES = {
	'p': 'pending',
	'a': 'approved',
	'r': 'rejected',
	'c': 'cancelled',
	'cm': 'cancelled',
	'cc': 'cancelled',
}