import React, {useState} from 'react'
import {ContentBox} from "../../../../components/ContentBox";
import AccountDetailsNav from "./components/AccountDetailsNav";
import {ACCOUNT_DETAILS_INFO_ID, ACCOUNT_DETAILS_PASSWORD_ID} from "../../../../constants/constants";
import AccountDetailsInfo from "./components/AccountDetailsInfo";
import AccountDetailsPassword from "./components/AccountDetailsPassword";
const AccountDetails = () => {
	
	const [activeTab, setActiveTab] = useState(ACCOUNT_DETAILS_INFO_ID)
	
	const handleClickTab = (id) => {
		setActiveTab(id)
	}
	const activeTabDisplay = (activeTab) => {
		switch (activeTab) {
			case ACCOUNT_DETAILS_INFO_ID:
				return <AccountDetailsInfo/>
			case ACCOUNT_DETAILS_PASSWORD_ID:
				return <AccountDetailsPassword/>
			default:
				return <AccountDetailsInfo/>
		}
	}
	
	return (
		<ContentBox sx={{flexDirection: 'column' }}>
			<AccountDetailsNav handleClickTab={handleClickTab} />
			{activeTabDisplay(activeTab)}
		</ContentBox>
	)
}

export default AccountDetails