import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionButton from "../../../../components/ActionButton/ActionButton";
import { ContentBox } from "../../../../components/ContentBox";
import {
	getAvailableDocs,
	getDocumentsList,
} from "../../../../store/reducers/kyc/actions";
import {
	selectKYCAvailableDocuments,
	selectKYCDocuments,
} from "../../../../store/reducers/kyc/selectors";
import VerificationDocumentsTable from "./components/VerificationDocumentsTable";
import AppModal from "../../../../components/AppModal/AppModal";
import VerificationUploadFileForm from "./components/VerificationUplodFileForm";
import AppModalQuastion from "../../../../components/AppModal/AppModalQuastion";
import VerificationPreview from "./components/VerificationPreview";
import VerificationPreviewPDF from "./components/VerificationPreviewPDF";

const Verification = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [showPreview, setShowPreview] = useState({
		isShow: false,
		dataUrl: null,
	});
	const documents = useSelector(selectKYCDocuments);
	const docs = useSelector(selectKYCAvailableDocuments);
	const [modals, setModals] = useState({
		question: {
			isShow: false,
			title: null,
			text: null,
			actionButtonTitle: null,
			action: () => {},
		},
		info: {
			isShow: false,
			title: null,
			text: null,
		},
	});

	const checkAvailableDocuments = () => {
		return Object.values(docs).reduce((acc, v) => acc + v, 0) <= 0;
	};

	const showUploadForm = () => setShowForm(true);
	const hideUploadForm = () => setShowForm(false);

	const showQuestionModal = (
		title,
		text,
		action,
		actionButtonTitle,
		buttonTitle
	) => {
		setModals((state) => {
			return {
				...state,
				question: {
					isShow: true,
					title,
					text,
					action,
					actionButtonTitle,
					buttonTitle,
				},
			};
		});
	};

	const hideQuestionModal = () => {
		setModals((state) => {
			return {
				...state,
				question: {
					isShow: false,
					title: null,
					text: null,
					action: null,
					actionButtonTitle: null,
					buttonTitle: null,
				},
			};
		});
	};

	const showInfoModal = (title, text, buttonTitle) => {
		setModals((state) => {
			return {
				...state,
				info: {
					isShow: true,
					title,
					text,
					buttonTitle,
				},
			};
		});
	};

	const hideInfoModal = (title, text) => {
		setModals((state) => {
			return {
				...state,
				info: {
					isShow: false,
					title,
					text,
					buttonTitle: null,
				},
			};
		});
	};

	const fetchDocuments = async () => {
		setLoading(true);
		try {
			await dispatch(getDocumentsList());
		} catch (error) {
			console.log("error", error);
		} finally {
			setLoading(false);
		}
	};

	const fetchAvailableTypes = async () => {
		setLoading(true);
		try {
			await dispatch(getAvailableDocs());
		} catch (error) {
			console.log("error", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchDocuments();
		fetchAvailableTypes();
	}, []);

	return (
		<ContentBox
			sx={{
				flexDirection: "column",
				overflow: "hidden",
			}}
		>
			{!showForm && !showPreview.isShow && (
				<>
					<ActionButton
						title={"Upload files"}
						color={"primary"}
						type={"submit"}
						sx={{ width: "auto", margin: "0 0 20px auto" }}
						onClick={() => showUploadForm()}
						disabled={checkAvailableDocuments()}
					/>
					{loading ? (
						<Box textAlign={"center"} mt={5}>
							<CircularProgress />
						</Box>
					) : documents?.details ? (
						<Box textAlign={"center"} mt={5}>
							<Typography sx={{ color: "white" }}>
								{documents.details}
							</Typography>
						</Box>
					) : (
						<VerificationDocumentsTable
							documents={documents}
							setShowPreview={setShowPreview}
							modalController={{
								quastion: {
									open: showQuestionModal,
									hide: hideQuestionModal,
								},
								info: {
									open: showInfoModal,
									hide: hideInfoModal,
								},
							}}
						/>
					)}
				</>
			)}
			{showForm && !showPreview.isShow && (
				<VerificationUploadFileForm
					modalController={{
						quastion: {
							open: showQuestionModal,
							hide: hideQuestionModal,
						},
						info: {
							open: showInfoModal,
							hide: hideInfoModal,
						},
					}}
					hideUploadForm={hideUploadForm}
				/>
			)}
			<AppModal
				isOpen={modals.info.isShow}
				title={modals.info.title}
				errorText={modals.info.text}
				handleClose={hideInfoModal}
				buttonTitle={modals.info.buttonTitle}
			/>
			<AppModalQuastion
				isOpen={modals.question.isShow}
				title={modals.question.title}
				errorText={modals.question.text}
				buttonTitle={modals.question.buttonTitle}
				actionButtonTitle={modals.question.actionButtonTitle}
				handleClose={hideQuestionModal}
				handleAction={modals.question.action}
			/>
		</ContentBox>
	);
};

export default Verification;
