import styled from "@emotion/styled";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useState } from "react";
import { UploadFileInput } from "./styled";
import { Box } from "@mui/system";
import { v4 as uuidv4 } from "uuid";
import { Typography } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
});

const ACCEPTED_FILE_TYPES = [
	"image/heic",
	"image/heif",
	"image/jpeg",
	"image/png",
	"image/webp",
	"image/svg+xml",
	"application/pdf",
];

const AppFormFileUpload = React.forwardRef(
	(
		{
			id,
			label,
			minRows,
			maxRows,
			sx,
			type,
			disabled,
			placeholder,
			autoComplete,
			value,
			required,
			onChange,
			inputProps,
			onKeyPress,
			onBlur,
			handleResetUploadedFile,
			handleUploadFile,
			filename,
			isValid,
		},
		ref
	) => {
		const htmlForUUID = uuidv4();

		return (
			<UploadFileInput
				style={{
					border: isValid != true ? "1px solid red" : "1px solid white",
				}}
			>
				<Typography
					style={{
						width: "auto",
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{filename}
				</Typography>
				<Box
					style={{
						display: "flex",
						width: "inherit",
						maxWidth: "fit-content",
					}}
				>
					{filename != "Select file" && (
						<CancelIcon
							sx={{ cursor: "pointer" }}
							onClick={handleResetUploadedFile}
						/>
					)}
					<label htmlFor={htmlForUUID}>
						<NoteAddIcon sx={{ cursor: "pointer" }} />
					</label>
				</Box>
				<VisuallyHiddenInput
					onChange={handleUploadFile}
					id={htmlForUUID}
					type={"file"}
					accept={ACCEPTED_FILE_TYPES.join(",")}
				/>
			</UploadFileInput>
		);
	}
);

export default AppFormFileUpload;
