import React from 'react'
import { AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ReactComponent as TraderIcon} from '../../assets/traderIcon.svg'
import {WebTraderAccordion} from "./styled";
import { useTranslation } from "react-i18next";

const WebTraderList = ({children}) => {
	const { t, i18n } = useTranslation();

	return(
		<Box height={'50%'} overflow={'hidden'}>
			<WebTraderAccordion>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon sx={{ color: 'white' }}/>}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<TraderIcon/>
					<Typography 
						sx={{ml:'18px', fontFamily: 'Roboto-Medium, sans-serif'}}
					>
						{ t('webTrader') }
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{children}
				</AccordionDetails>
			</WebTraderAccordion>
		</Box>
	)
}

export default WebTraderList