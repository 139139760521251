import { kycApi } from "../../../api/kyc";
import { formatDate } from "../../../utils";
import { refreshToken2 } from "../auth/actions";

export const getAvailableDocs = (params) => {
	return async (dispatch) => {
		try {
			const token = sessionStorage.getItem("access_token");
			const result = await kycApi.getAvailableDocs(token);

			dispatch({
				type: "GET_AVAILABLE_DOCS",
				payload: result.data,
			});
		} catch (error) {
			console.log("error", error);
			if (error.response.status === 401) {
				await dispatch(refreshToken2());
				return dispatch(getAvailableDocs(params));
			}
		}
	};
};

export const getDocumentsList = () => {
	return async (dispatch) => {
		try {
			const token = sessionStorage.getItem("access_token");
			const result = await kycApi.getDocs(token);

			const formatedDocuments = [];

			if (!result.data.details) {
				Object.entries(result.data).forEach((item) => {
					Object.entries(item[1]).forEach(async (entry) => {
						const [key, value] = entry;

						formatedDocuments.push({
							id: key,
							type: item[0],
							url: value.file_link,
							fileData: null,
							createdDate: formatDate(value.created_at),
						});
					});
				});
			}

			const documentsWithFileData = formatedDocuments.map(async (document) => {
				if (!document.fileData) {
					return await fetch(document.url).then(async (response) => {
						return {
							...document,
							fileData: await response.blob(),
						};
					});
				} else {
					return document;
				}
			});

			const items = await Promise.all(documentsWithFileData);

			dispatch({
				type: "GET_DOCUMENTS_LIST",
				payload: !result.data.details ? items : result.data,
			});
		} catch (error) {
			console.log("error", error);
			if (error.response.status === 401) {
				await dispatch(refreshToken2());
				return dispatch(getDocumentsList(params));
			}
		}
	};
};

export const deleteDocument = (params) => {
	return async (dispatch) => {
		try {
			const token = sessionStorage.getItem("access_token");
			const result = await kycApi.deleteDoc(token, params);

			dispatch({
				type: "DELETE_DOCUMENT",
				payload: {
					result: result.data,
					data,
				},
			});
		} catch (error) {
			console.log("error", error);
			if (error.response.status === 401) {
				await dispatch(refreshToken2());
				return dispatch(deleteDocument(params));
			}
		}
	};
};
