import {withdrawApi} from "../../../api/withdraw";
import {refreshToken2} from "../auth/actions";

export const getAccountsList = (accountsType) => {
	return async dispatch => {
		try {
			const token = sessionStorage.getItem('access_token')
			const result = await withdrawApi.getAccounts(token, accountsType)
			switch (accountsType) {
				case 'Real': {
					dispatch({
						type: 'GET_LIVE_ACCOUNTS',
						payload: result.data.data
					})
					break
				}
				case 'Demo': {
					dispatch({
						type: 'GET_DEMO_ACCOUNTS',
						payload: result.data.data
					})
					break
				}
				default: {
					dispatch({
						type: 'GET_ALL_ACCOUNTS',
						payload: result.data.data
					})
				}
			}
		} catch (error) {
			console.log('error', error)
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
				return dispatch(getAccountsList(accountsType))
			}
		}
	}
}