import {store} from "../store";
import {updateIsUserAuth, updateIsUserInvalid} from "../store/reducers/auth/actions";


export const createInactiveUserInterceptor = (instance) => {
    instance.interceptors.response.use(
        res => res,
        error => {
            if (error.response.status === 403) {
                store.dispatch(updateIsUserAuth(false))
                store.dispatch(updateIsUserInvalid(error.response.data.detail))
            }

            return Promise.reject(error);
        })
}