import instance from "./index";


export const transactionHistoryApi = {
	getAllTransactions: async (token, params) => {
		return instance.get('/transaction/list/',
			{
				params: params,
				headers:{
					authorization: `Bearer ${token}`
				}
			}
		)
	}
}