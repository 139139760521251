import {Button, styled} from "@mui/material";
const HeaderBox = styled('div')`
  //width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 19px 24px;
  background-color:${props => props.theme.palette.bgColors.headerBg};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @media (max-width: 375px){
	  h1 {
        font-size: 18px;
      }
  }
`

const HamburgerButton = styled(Button)`
//{color:'black', p:0, minWidth:'unset', display:{xs: 'inline-flex',md: 'none'}}
  color: white;
  min-width:unset;
  display: none;
  padding: 0;
  @media (max-width: 900px){
    display: inline-flex;
  }
`

const LanguageSelector = styled('div')`
	position: relative;
	z-index: 5;
	display: flex;
	flex-direction: row;
	gap: 8px;
	justify-content: center;
	align-items: center;
	max-width: 160px;
	margin-left: auto;
	padding: 9px 16px;
	background: ${props => props.theme.palette.bgColors.color4};
	cursor: pointer;
	margin-right: 10px;
	border-radius: 5px;
	transition: all .3s ease;

	&:not(.open):hover {
		background-color: ${props => props.theme.palette.bgColors.color4};
    opacity: 0.8;
	}

	& svg {
		max-height: 16px;
	}

	@media (max-width: 575px) {
		& {
			max-width: 115px;
		}
	}

	&.open .arrow-icon {
		transform: rotate(180deg);
	}
	&.open .languageSelector-options {
		display: flex;
    border: 1px solid ${props => props.theme.palette.bgColors.color4};
    background: ${props => props.theme.palette.bgColors.color2};
	}
	& p {
		font-weight: 600;
		font-size: 14px;
		line-height: 20px;
		color: ${props => props.theme.palette.primary.contrastText};
	}
	& .languageSelector-label {
		margin: 0px;
	}
	& .languageSelector-options {
		width: 100%;
		position: absolute;
		top: 110%;
		left: 0;
		display: none;
		flex-direction: column;
		background-color: #313131;
		box-shadow: 0 15px 30px 0 rgba(41, 45, 50, 0.1);
		border-radius: 8px;
		padding: 8px 0;
	}
	& .languageSelector-options-item {
		padding: 8px 16px;
		display: flex;
		flex-direction: row;
		gap: 8px;
		justify-content: flex-start;
		align-items: center;
	}
	& .languageSelector-options-item p {
		font-weight: 600;
		font-size: 15px;
		line-height: 24px;
		color: ${props => props.theme.palette.primary.contrastText};
		margin: 0px;
	}

	& .languageSelector-options-item p:last-child {
		display: none;
	}

	@media (max-width: 720px) {
		& .languageSelector-options-item p:last-child {
			display: block;
		}
		& .languageSelector-options-item p:first-child {
			display: none;
		}
	}

	& .languageSelector-options-item:hover p {
		color: ${props => props.theme.palette.bgColors.color4};
		opacity: 0.8;
	}
`;

export { HeaderBox, HamburgerButton, LanguageSelector }