import instance from "./index";

export const pagesForRedirectsApi = {
    getLoginPage: async (clientAreaUrl) => {
        return instance.get('/get_login_page', {
            headers: {
                'CLIENT-AREA-HOST': clientAreaUrl
            },
            transformRequest: (data, headers) => {
                delete headers.authorization
                return JSON.stringify(data)
            }
        })
    }
}