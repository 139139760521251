const initialState = {
	currencies: [],
	accountTypes: [],
	citizenships:[],
	countries:[],
	clientDetails: {},
	brandContact: {}
}

export const detailsReducer = (state = initialState, action) => {
	const { type, payload } = action
	
	switch (type){
		case 'GET_CURRENCIES':{
			return {
				...state,
				currencies: payload
			}
		}
		case 'GET_ACCOUNT_TYPES':{
			return {
				...state,
				accountTypes: payload
			}
		}
		case 'GET_CITIZENSHIPS':{
			return {
				...state,
				citizenships: payload
			}
		}
		case 'GET_COUNTRIES':{
			return {
				...state,
				countries: payload
			}
		}
		case 'GET_CLIENT_DETAILS':{
			return {
				...state,
				clientDetails: payload
			}
		}
		case 'CHANGE_CLIENT_DETAILS':{
			
			return {
				...state,
				clientDetails:{
					...state.clientDetails,
					[payload.key]: payload.value
				}
			}
		}
		case 'CHANGE_CLIENT_BIRTH':{
			
			return {
				...state,
				clientDetails:{
					...state.clientDetails,
					birth_date: payload
				}
			}
		}
		
		case 'GET_BRAND_CONTACT':{
			return {
				...state,
				brandContact: payload
			}
		}
		default:{
			return {...state}
		}
		
	}
}