import {Box, Modal, styled} from "@mui/material";


const StyledModal = styled(Modal)`

`
const StyledModalBox = styled(Box)`
  z-index: 1300;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 280px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.palette.primary.contrastText};
  border-radius: 4px;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.14), 0 6px 30px rgba(0, 0, 0, 0.12), 0 8px 10px rgba(0, 0, 0, 0.2);
  padding: 16px 16px 8px 24px
`
const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
`

export {StyledModal, Backdrop, StyledModalBox}