import instance from "./index";


export const withdrawApi = {
	getAccounts: async (token, accountType) => {
		return instance.get('/client/trading_accounts/list/',
			{
				params: {
					account_type: accountType
				},
				headers:{
					authorization: `Bearer ${token}`
				}
			})
	},

	createWithdraw: async (token, accountId, amount) => {
		return instance.post('/transaction/withdraw/create/',
			{
				trading_account_id: String(accountId),
				amount: Number(amount)
			},
			{
				headers:{
					authorization: `Bearer ${token}`
				}
			}
		)
	},

	getWithdrawTransactions: async (token, account = ' ', period = ' ', status = ' ') => {
		return instance.get(
			`/transaction/withdraw/list/`,
			{
				params: {
					trading_account_id: account,
					period: period,
					status: status
				},
				headers:{
					authorization: `Bearer ${token}`
				}
			}
		)
	},

	cancelWithdrawTransaction: async ( token, transactionId, accountId) => {
		return instance.post('/transaction/withdraw/cancel/',
			{
				transaction_id: transactionId,
				trading_account_id: accountId
			},
			{
				headers:{
					authorization: `Bearer ${token}`
				}
			})
	}
}