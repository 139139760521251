import React, {useState} from 'react'
import {WITHDRAW_HISTORY_ID, WITHDRAW_REQUEST_ID} from "../../../../../constants/constants";
import {Box, Tab, Tabs} from "@mui/material";
import theme from "../../../../../theme";
import { useTranslation } from "react-i18next";


const withdrawTabs = [
	{
		id: WITHDRAW_REQUEST_ID,
		title: 'withdrawRequest'
	},
	{
		id: WITHDRAW_HISTORY_ID,
		title: 'withdrawHistory'
	}
]
const WithdrawNav = ({handleClickTab}) => {
	const { t, i18n } = useTranslation()
	const innerWidth = window.innerWidth
	const [value, setValue] = useState(WITHDRAW_REQUEST_ID)
	
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	
	return(
		<Box mb={'40px'} ml={{xs:'-20px', md:0}} mr={'-20px'}>
			<Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example" centered={innerWidth < 900}>
				{withdrawTabs ?
					withdrawTabs.map(tab => (
						<Tab
							key={tab.id}
							value={tab.id}
							label={t(tab.title)}
							sx={{pb: '0', fontSize:{xs: '12px', md: '14px'}}}
							onClick={() => handleClickTab(tab.id)}
						/>
					)) : ''
				}
			</Tabs>
		</Box>
	)
}

export default WithdrawNav