import React from 'react'
import {Box} from "@mui/material";

const WebTrader = ({src}) => {
	return(
		<Box width={'100%'} height={'100%'} boxSizing={'border-box'}>
			<iframe src={src} width={'100%'} height={'100%'} frameBorder={'unset'} />
		</Box>
	)
}

export default WebTrader