import instance from "./index";

export const kycApi = {
	getAvailableDocs: async (token) => {
		return instance.get("/kyc/available_docs", {
			headers: {
				authorization: `Bearer ${token}`,
			},
		});
	},
	getDocs: async (token) => {
		return instance.get("/kyc/get_docs/", {
			headers: {
				authorization: `Bearer ${token}`,
			},
		});
	},
	deleteDoc: async (token, data) => {
		return instance.post("/kyc/delete/", data, {
			headers: {
				authorization: `Bearer ${token}`,
			},
		});
	},
	uploadDocs: async (token, data) => {
		return instance.post("/kyc/upload/", data, {
			headers: {
				authorization: `Bearer ${token}`,
				["Content-Type"]: "multipart/form-data",
			},
		});
	},
};
