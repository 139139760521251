import CloseIcon from "@mui/icons-material/Close";

import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Viewer, Worker } from "@react-pdf-viewer/core";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function VerificationPreviewPDF({ url, setShowPreview }) {
	const defaultLayoutPluginInstance = defaultLayoutPlugin();

	return (
		<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<CloseIcon
				onClick={() =>
					setShowPreview(() => ({
						isShow: false,
						dataUrl: null,
					}))
				}
				fontSize="large"
				style={{
					margin: "0px 0px 0px auto",
					display: "block",
					marginBottom: 20,
				}}
				color="primary"
			/>
			<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
				<Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]}></Viewer>
			</Worker>
		</div>
	);
}

export default VerificationPreviewPDF;
