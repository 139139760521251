import React from 'react'
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"
import { useTranslation } from "react-i18next";

const ManageAccountsLiveTable = ({children}) => {
	const { t, i18n } = useTranslation();

	return(
		<Box mt={'30px'} width={'100%'} sx={{height: {md: 'calc(100% - 85px)', xs: 'calc(100% - 150px)'}}}>
			<TableContainer
				sx={{ height: '100%', overflow: 'auto'}}
			>
				<Table
					sx={{minWidth: 540}} aria-label="withdraw-history-table" stickyHeader
				>
					<TableHead >
						<TableRow>
							<TableCell>{ t('account') }</TableCell>
							<TableCell align="left">{ t('leverage') }</TableCell>
							<TableCell align="left">{ t('currency') }</TableCell>
							<TableCell align="left">{ t('equity') }</TableCell>
							<TableCell align="left">{ t('balance') }</TableCell>
							<TableCell align="left">{ t('credit') }</TableCell>
							<TableCell align="left">{ t('status') }</TableCell>
							<TableCell align="left">{ t('readOnly') }</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						
						{children}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}

export default ManageAccountsLiveTable