import {manageAccountsApi} from "../../../api/manageAccounts";
import {clientDetails} from "../../../api/clientDetails";
import {refreshToken2} from "../auth/actions";
import {depositApi} from "../../../api/deposit";
import dayjs from "dayjs";

export const getCurrencies = () => {
	return async dispatch => {
		try{
			const token = sessionStorage.getItem('access_token')
			const result = await manageAccountsApi.getCurrenciesTypes(token)
			dispatch({
				type: 'GET_CURRENCIES',
				payload: result.data.data
			})
		} catch (error){
			console.log(error)
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
				return dispatch(getCurrencies())
			}
		}
	}
}

export const getAccountTypesList = () => {
	return async dispatch => {
		try{
			const token = sessionStorage.getItem('access_token')
			const result = await manageAccountsApi.getAccountTypes(token)
			dispatch({
				type: 'GET_ACCOUNT_TYPES',
				payload: result.data.data
			})
		} catch (error){
			console.log(error)
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
				return dispatch(getAccountTypesList())
			}
		}
	}
}

export const getCitizenships = () => {
	return async dispatch => {
		try{
			const token = sessionStorage.getItem('access_token')
			const result = await clientDetails.getCitizenshipList(token)
			dispatch({
				type: 'GET_CITIZENSHIPS',
				payload: result.data.data
			})
		} catch (error){
			console.log('error', error)
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
				return dispatch(getCitizenships())
			}
		}
	}
}

export const getCountries = () => {
	return async dispatch => {
		try{
			const token = sessionStorage.getItem('access_token')
			const result = await clientDetails.getCountries(token)
			dispatch({
				type: 'GET_COUNTRIES',
				payload: result.data.data
			})
		} catch (error){
			console.log('get countries error', error)
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
				return dispatch(getCountries())
			}
		}
	}
}

export const getClientDetails = () => {
	return async dispatch => {
		try{
			const token = sessionStorage.getItem('access_token')
			const result = await clientDetails.getClientsProfile(token)
			dispatch({
				type: 'GET_CLIENT_DETAILS',
				payload: result.data
			})
		}catch (error) {
			console.log('get clients details error', error)
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
				return dispatch(getClientDetails())
			}
		}
	}
}

export const changeClientDetails = (event,detailKey ) => {
	return dispatch => {
		
		dispatch({
			type: 'CHANGE_CLIENT_DETAILS',
			payload: {
				key: detailKey,
				value: event.target.value
			}
		})
	}
}

export const changeClientBirthDate = (event) => {
	const date = dayjs(event).format('MM/DD/YYYY')
	return dispatch => {
		dispatch({
			type: 'CHANGE_CLIENT_BIRTH',
			payload: event === null ? event : date
		})
	}
}
export const getBrandContact = () => {
	return async dispatch => {
		try{
			const token = sessionStorage.getItem('access_token')
			const result = await depositApi.getBrandContact(token)
			dispatch({
				type: 'GET_BRAND_CONTACT',
				payload: result.data
			})
		}catch (error){
			console.log('get brand contact error', error)
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
				return dispatch(getBrandContact())
			}
		}
	}
}

