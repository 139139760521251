
export const selectCurrencies = (state) => state.details.currencies

export const selectAccountTypes = (state) => state.details.accountTypes

export const selectCitizenships = (state) => state.details.citizenships

export const selectCountries = (state) => state.details.countries

export const selectClientDetails = (state) => state.details.clientDetails

export const selectBrandContact = (state) => state.details.brandContact