import axios from "axios";
import {createInactiveUserInterceptor} from "./interceptors";

const apiUrl = process.env.REACT_APP_URL + '/api/v1'
const instance = axios.create({
	baseURL: apiUrl,
	headers: {
		['x-api-key']: process.env.REACT_APP_API_KEY,
		authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
		['Content-Type']: 'application/json'
	},
	data: {}
})

createInactiveUserInterceptor(instance)

export default instance