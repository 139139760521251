import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
	lang: "en",
	fallbackLng: "en",
	resources: {
		en: {
			translation: {
				logout: "Logout",
				greeting: "Hi",
				saveChanges: "Save Changes",
				transferAccounts: "Transfer between accounts",
				openNewAccount: "Open new account",
				cancel: "Cancel",
				createAccount: "Create account",
				makeTransfer: "Make transfer",
				withdrawFunds: "Withdraw funds",
				close: "CLOSE",
				webTrader: "Web Trader",
				paymentDetails: "Payment Details",
				transactionHistory: "Transaction history",
				accountDetails: "Account details",
				withdraw: "Withdraw",
				withdrawal: "Withdrawal",
				manageAccounts: "Manage Accounts",
				deposit: "Deposit",
				allPeriod: "All period",
				today: "Today",
				last3days: "Last 3 days",
				lastWeek: "Last week",
				lastMonth: "Last month",
				last3month: "Last 3 month",
				all: "All",
				creditIn: "Credit in",
				creditOut: "Credit out",
				pending: "Pending",
				approved: "Approved",
				cancelled: "Cancelled",
				rejected: "Rejected",
				wrongPasswordFormat: "Wrong format of password",
				error: "Error",
				invalidBirthDate: "Invalid date of birth",
				firstName: "First name",
				lastName: "First name",
				birthDate: "Date of birth",
				city: "City",
				address: "Address",
				citizenship: "Citizenship",
				zipCode: "Zip code/Postcode",
				personalInformation: "PERSONAL INFORMATION",
				changePassword: "CHANGE PASSWORD",
				success: "Success",
				passwordSuccessfullyChanged: "Your password is successfully changed",
				mustEnterOldPassword: "You must enter old password.",
				oldPassword: "Old Password*",
				mustSpecifyPassword: "You must specify password.",
				passwordMustLeast: "Password must have at least 8 characters.",
				passwordMustContain: "Password must contain up to 12 characters.",
				passwordMustEqual: "The value is not equal to the New Password",
				newPassword: "New Password*",
				repeatPassword: "Repeat Password*",
				noDemoAccounts: "No demo accounts",
				live: "LIVE",
				demo: "DEMO",
				amount: "Amount",
				noData: "No data",
				chooseAnAccount: "Choose an account to withdraw from",
				fail: "Fail",
				requestSuccessfullyCreated: "Withdraw request is successfully created",
				balance: "Balance",
				readOnly: "Read only",
				withdrawRequest: "WITHDRAW REQUEST",
				withdrawHistory: "WITHDRAW HISTORY",
				period: "Period",
				status: "Status",
				account: "Account",
				noTransactions: "No transactions yet",
				type: "Type",
				confirmTransfer: "I confirm to perform a transfer",
				amountInEUR: "Amount in EUR*",
				transferToAccount: "Transfer to account*",
				transferFromAccount: "Transfer from account*",
				transferSuccessfullyPerformed: "Transfer is successfully performed",
				country: "Country",
				passwordRules: `
						Your password should have at least 8 symbols, but not more than 12 symbols, should contain
						at
						least: 1 uppercase letter, 1 lowercase letter, 1 digit
					`,
				createPasswordRecommendations: `
						You may set a new password for your account here.
						It is strongly recommended to select a password that contains Latin characters and digits combined,
						and cannot be easily guessed.
					`,
				performDeposit:
					"To perform a deposit, please contact your Account Manager directly or Support Team at",
				or: "or",
				noLiveAccount: "No live accounts",
				active: "Active",
				inactive: "Inactive",
				true: "True",
				false: "False",
				leverage: "Leverage",
				currency: "Currency",
				equity: "Equity",
				credit: "Credit",
				accountSuccessfullyCreated: "Account is successfully created",
				accountType: "Account type*",
				currency: "Currency*",
				transferForm: "Transfer form",
				openAccount: "Open account",
				policy: `
					This form serves as an official request to withdraw and transfer funds from the brokers trading
					account into the account specified below.
					I understand that the broker does not permit any third party transfers. The beneficiary contact
					listed in my transfer details below, is the same as the owner of my trading account.
					I understand that I might be subject to the appropriate bank fees and transfer charges
					applicable to this request.
					I authorize the broker to deduct the necessary fees from my account or reduce my transfer amount
					accordingly.
					I understand that the broker may request additional information, identification or verification
					from me in order to authorize my request.
					I further understand that although withdrawals are dealt with promptly, it can take
					approximately seven business days before the funds appear on my account statement,
					provided that all documentation required from me by the broker have been reviewed and approved
					to their satisfaction.
			`,
			},
		},
		es: {
			translation: {
				logout: "Cerrar sesión",
				greeting: "Hola",
				saveChanges: "Guardar Cambios",
				transferAccounts: "Transferencia entre cuentas",
				openNewAccount: "Abrir una nueva cuenta",
				cancel: "Cancelar",
				createAccount: "Crear cuenta",
				makeTransfer: "Hacer la transferencia",
				withdrawFunds: "Retirar fondos",
				close: "CERRAR",
				webTrader: "Web Trader",
				paymentDetails: "Detalles de Pago",
				transactionHistory: "Historial de Transacciones",
				accountDetails: "Detalles de la Cuenta",
				withdraw: "Retirar",
				withdrawal: "La Retirada",
				manageAccounts: "Administrar Cuentas",
				deposit: "Fuerte",
				allPeriod: "Todo el período",
				today: "El día de Hoy",
				last3days: "El Pasado 3 días",
				lastWeek: "La semana Pasada",
				lastMonth: "El mes Pasado",
				last3month: "Últimos 3 meses",
				all: "Todos",
				creditIn: "Crédito",
				creditOut: "Crédito",
				pending: "Pendiente",
				approved: "Aprobado",
				cancelled: "Cancelado",
				rejected: "Rechazado",
				wrongPasswordFormat: "Formato Incorrecto de la contraseña",
				error: "Error",
				invalidBirthDate: "No Válido 'fecha de nacimiento'",
				firstName: "Nombre",
				lastName: "Nombre",
				birthDate: "Fecha de nacimiento",
				city: "La Ciudad",
				address: "Direccion",
				citizenship: "La Ciudadanía",
				zipCode: "El código Postal/código postal",
				personalInformation: "INFORMACIÓN PERSONAL",
				changePassword: "CAMBIAR CONTRASEÑA",
				success: "Éxito",
				passwordSuccessfullyChanged: "Su contraseña se cambia correctamente",
				mustEnterOldPassword: "Debe introducir la contraseña antigua.",
				oldPassword: "Contraseña Antigua*",
				mustSpecifyPassword: "Debe especificar la contraseña.",
				passwordMustLeast: "La Contraseña debe tener al menos 8 caracteres.",
				passwordMustContain:
					"La Contraseña debe contener un máximo de 12 caracteres.",
				passwordMustEqual: "El valor no es igual a la Contraseña Nueva",
				newPassword: "Nueva Contraseña*",
				repeatPassword: "Repetir Contraseña*",
				noDemoAccounts: "Ninguna de las cuentas de demostración",
				live: "VIVIR",
				demo: "DEMO",
				amount: "Cantidad",
				noData: "No hay datos",
				chooseAnAccount: "Elija una cuenta para retirarse",
				fail: "Error",
				requestSuccessfullyCreated: "Solicitud de retiro es creado con éxito",
				balance: "Equilibrio",
				readOnly: "Sólo Lectura",
				withdrawRequest: "SOLICITUD de retiro",
				withdrawHistory: "RETIRAR de la HISTORIA",
				period: "Período",
				status: "El Estado",
				account: "Cuenta",
				noTransactions: "No hay transacciones sin embargo",
				type: "Tipo",
				confirmTransfer: "Puedo confirmar para realizar una transferencia",
				amountInEUR: "Importe en EUROS*",
				transferToAccount: "La Transferencia a la cuenta*",
				transferFromAccount: "La Transferencia de la cuenta*",
				transferSuccessfullyPerformed: "La Transferencia se realizó con éxito",
				country: "El País",
				passwordRules: `
					Su contraseña debe tener al menos 8 símbolos, pero no más de 12 símbolos,
					debe contener en menos:
						1 letra mayúscula,
						1 letra minúscula,
						1 dígito
				`,
				createPasswordRecommendations: `
					Usted puede establecer una contraseña nueva para su cuenta aquí.
					Se recomienda que seleccione una contraseña que contenga letras y dígitos combinados,
					y no se puedan adivinar fácilmente.
				`,
				performDeposit:
					"Para realizar un depósito, por favor póngase en contacto con su Gerente de Cuenta o con el Equipo de asistencia",
				or: "o",
				noLiveAccount: "No vivo de cuentas",
				active: "Activo",
				inactive: "Inactivo",
				true: "El Verdadero",
				false: "False",
				leverage: "Apalancamiento",
				currency: "La Moneda",
				equity: "Equidad",
				credit: "La Equidad",
				accountSuccessfullyCreated: "La Cuenta se ha creado correctamente",
				accountType: "Tipo de Cuenta*",
				currency: "La Moneda*",
				transferForm: "Formulario de Transferencia",
				openAccount: "Cuenta abierta",
				policy: `
					Este formulario sirve como una petición oficial para retirar y transferir fondos de los corredores de comercio
					cuenta en la cuenta indicada a continuación.
					Entiendo que el corredor no se permitirá que ningún tercero transferencias. El beneficiario de contacto
					aparece en mi transferencia de datos a continuación, es el mismo que el titular de mi cuenta de trading.
					Yo entiendo que puede ser objeto del correspondiente comisiones bancarias y los gastos de transferencia
					aplicable a esta solicitud.
					Yo autorizo el corredor a deducir las cuotas necesarias de mi cuenta o reducir el monto de la transferencia
					en consecuencia.
					Entiendo que el corredor puede solicitar información adicional, la identificación o verificación
					de mí para autorizar mi petición.
					Además, entiendo que a pesar de los retiros sean atendidas con rapidez, puede tomar
					aproximadamente siete días hábiles antes de que los fondos aparezcan en mi estado de cuenta,
					a condición de que toda la documentación que se requiera de mí por el corredor, han sido revisados y aprobados
					para su satisfacción.
				`,
			},
		},
	},
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
