import instance from "./index";


export const depositApi = {
	
	getBrandContact: async (token) => {
		return instance.get('/client/brands_email_and_phone/',
			{
				headers:{
					authorization: `Bearer ${token}`
				}
			})
	}
}