import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ChevronDownIcon } from "../../../assets/chevron-down.svg";
import { ReactComponent as GlobeIcon } from "../../../assets/globe-icon.svg";
import { LanguageSelector } from "./styled";

const languagesList = [
	{
		title: "English",
		shortTitle: "En",
		code: "en",
	},
	{
		title: "Spanish",
		shortTitle: "Es",
		code: "es",
	},
];

const LanguageSwitcher = () => {
	const [currentLang, setCurrentLang] = useState(languagesList[0].title);
	const [isOpen, setIsOpen] = useState(false);
	const { t, i18n } = useTranslation();

	const setLang = (code) => {
		i18n.changeLanguage(code);
		localStorage.setItem("ca-lang", code);
		setCurrentLang(languagesList.filter((l) => l.code === code)[0].title);
	};

	useEffect(() => {
		setLang(localStorage.getItem("ca-lang") || "en");
	}, [currentLang]);

	return (
		<LanguageSelector
			className={`${isOpen ? "open" : ""}`}
			onClick={() => setIsOpen(!isOpen)}
		>
			<GlobeIcon className="earth-icon" />
			<p className="languageSelector-label" data-label="en">
				{currentLang}
			</p>
			<ChevronDownIcon className="arrow-icon" />
			<div className="languageSelector-options">
				{languagesList.map((lang) => (
					<div
						key={lang.code}
						className="languageSelector-options-item"
						onClick={() => setLang(lang.code)}
					>
						<p className="d-none d-md-block">{lang.title}</p>
						<p className="d-block d-md-none">{lang.shortTitle}</p>
					</div>
				))}
			</div>
		</LanguageSelector>
	);
};

export default LanguageSwitcher;
