const initialState = {
	allTransactions: [],
	withdrawTransactions: []
}

export const transactionReducer = (state = initialState, action) => {
	const {type, payload} = action
	switch (type){
		case 'GET_ALL_TRANSACTIONS':{
			return {
				...state,
				allTransactions: payload
			}
		}
		case 'GET_WITHDRAW_TRANSACTIONS': {
			return {
				...state,
				withdrawTransactions: payload
			}
		}
		default:{
			return {...state}
		}
	}
}

