import React, {useState} from 'react'
import {IconButton, InputAdornment, OutlinedInput} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {PasswordLabel} from "./styled";
import {customStyles} from "./sharedCustomStyles";

const InputPassword = React.forwardRef(({id, value, name,onChange, onBlur, label,error, ...res}, ref) => {
	const [showPassword, setShowPassword] = useState(false)
	const handleClickShowPassword = (event) => {
		event.stopPropagation()
		setShowPassword(!showPassword)
	}
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	
	return(
		<>
			<PasswordLabel htmlFor="outlined-adornment-password" error={error}>{label}</PasswordLabel>
			<OutlinedInput
				id={id}
				sx={customStyles}
				name={name}
				type={showPassword ? 'text' : 'password'}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="toggle password visibility"
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
							edge="end"
						>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				}
				label={label}
				error={error}
				ref={ref}
				{...res}
				autoComplete={'off'}
			/>
		</>
	)
})
export default InputPassword