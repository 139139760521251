import {applyMiddleware, combineReducers, createStore} from "redux";
import {configureStore} from "@reduxjs/toolkit";
import {authReducer} from "./reducers/auth/reducers";
import {detailsReducer} from "./reducers/details/reducers";
import {transactionReducer} from "./reducers/transactions/reducers";
import {accountReducer} from "./reducers/accounts/reducers";
import { kycReducer } from "./reducers/kyc/reducers";

const combinedReducers = combineReducers({
	auth: authReducer,
	details: detailsReducer,
	transactions: transactionReducer,
	accounts: accountReducer,
	documents: kycReducer
})

const rootReducer = (state, action) => {
	return combinedReducers(state, action)
}
export const store = configureStore({
	reducer: rootReducer,
})