export const initialState = {
	accessToken: sessionStorage.getItem('access_token') || '',
	refreshToken: sessionStorage.getItem('refresh_token') || '',
	expiresIn: sessionStorage.getItem('expires_in') || '',
	accountsForWT: [],
	authSucceed: false,
	isUserInvalid: {
		status: false,
		message: ''
	},
	logoutLoading: false,
}


export const authReducer = (state = initialState, action) => {
	const { type, payload } = action

	switch (type){
		case 'AUTH': {
			const { access_token, refresh_token, expires_in, accounts } = payload
			return {
				...state,
				accessToken: access_token,
				refreshToken: refresh_token,
				expiresIn: expires_in,
				accountsForWT: accounts,
				authSucceed: !!access_token
			}
		}
		case 'REFRESH_TOKEN': {
			const { access_token, refresh_token, expires_in, accounts } = payload
			return {
				...state,
				accessToken: access_token,
				refreshToken: refresh_token,
				expiresIn: expires_in,
				accountsForWT: accounts,
				authSucceed: !!access_token
			}
		}

		case 'UPDATE_ACCOUNTS_FOR_WT': {
			const { trading_account_number, trading_account_type, webtrader_url, webtrader_token } = payload
			return {
				...state,
				accountsForWT:[
					...state.accountsForWT,
					{
						account: trading_account_number,
						account_type: trading_account_type,
						webtrader_url: webtrader_url,
						webtrader_token: webtrader_token
					}
				]
			}
		}

		case 'SET_IS_USER_INVALID': {
			return {
				...state,
				isUserInvalid: {
					status: true,
					message: payload
				}
			}
		}

		case 'SET_IS_AUTH': {
			return {
				...state,
				authSucceed: payload,
			}
		}

		case 'SET_LOGOUT_LOADING': {
			return  {
				...state,
				logoutLoading: payload
			}
		}

		default:
			return {...state}
	}
}