import React from 'react'
import {FormControlApp} from "./styled";

const AppFormControl = ({children, error, sx, margin, color}) => {
	
	return(
		<FormControlApp 
			error={error}
			sx={sx}
			margin={margin}
			color={color}
			fullWidth={true}
		>
			{children}
		</FormControlApp>
	)
}

export default AppFormControl