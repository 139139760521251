import React, {useEffect, useState} from 'react'
import {Box, CircularProgress, Grid, MenuItem, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import AppFormControl from "../../../../../components/AppForm/AppFormControl";
import AppFormFieldInput from "../../../../../components/AppForm/AppFormFieldInput";
import ActionButton from "../../../../../components/ActionButton/ActionButton";
import {withdrawApi} from "../../../../../api/withdraw";
import AppModal from "../../../../../components/AppModal/AppModal";
import AppFormSelectWithoutDefault from "../../../../../components/AppForm/AppFormSelectWithoutDefault";
import {useDispatch, useSelector} from "react-redux";
import {selectAllAccounts} from "../../../../../store/reducers/accounts/selectors";
import {getAccountsList} from "../../../../../store/reducers/accounts/actions";
import {refreshToken2} from "../../../../../store/reducers/auth/actions";
import { useTranslation } from "react-i18next";

const WithdrawRequest = () => {
	const dispatch = useDispatch()
	const { t, i18n } = useTranslation()
	const [showModal, setShowModal] = useState(false)
	const [loading, setLoading] = useState(true)

	const [modalContent, setModalContent] = useState({
		title: '',
		text: ''
	})
	const [withdrawRequestForm, setWithdrawRequestForm] = useState({
		account: '',
		amount: ''
	})

	const {handleSubmit, setValue} = useForm({
		mode: 'all',
		defaultValues: {
			account: '',
			amount: ''
		}
	})
	const accountsList = useSelector(selectAllAccounts).map(item => {
		return {
			title: `#${item.trading_account_id} ${t('balance')}: ${item.currency}${item.balance}${item.read_only === true ? ` ${t('readOnly')}` : ''}`,
			id: item.trading_account_id
		}
	})
	const fetchAccounts = async () => {
		try {
			setLoading(true)
			await dispatch(getAccountsList())
			
		} catch (error) {
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}

	const createWithdraw = async (account, amount) => {
		try {
			const token = sessionStorage.getItem('access_token')
			const res = await withdrawApi.createWithdraw(token, account, amount)
			setShowModal(true);
			setModalContent({title: t('success'), text: t('requestSuccessfullyCreated') })
			setWithdrawRequestForm({account: '', amount: ''})
		} catch (error) {
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
				return createWithdraw(account, amount)
			} else {
				setShowModal(true);
				setModalContent({title: t('fail'), text: error.response.data.details || error.response.data.detail})
			}
		}
	}
	
	useEffect(() => {
		fetchAccounts()
	}, [])

	const onSubmit = async (data) => {
		const {account, amount} = data
		await createWithdraw(account, amount)
	}

	const handleChangeWithdrawForm = (event, inputName) => {
		const formCopy = {...withdrawRequestForm}
		formCopy[inputName] = event.target.value
		setWithdrawRequestForm(formCopy)
		setValue(inputName, event.target.value)
	}

	const handleCloseModal = () => {
		setShowModal(false)
	}

	return (
		<Box
			component={'form'}
			onSubmit={handleSubmit(onSubmit)}
			height={'100%'}
		>
			<Box
				border={'none'}
				p={0}
				height={'100%'}
				display={'flex'}
				flexDirection={'column'}
			>
				<Typography variant={'body2'} mb={{xs: '20px', md: '60px'}}>
					{ t('policy') }
				</Typography>
				<Grid container columnSpacing={{md: 5}} rowSpacing={{md: 10, xs: 3}}>
					<Grid item xs={12} md={7} lg={5}>
						<AppFormControl sx={{mb: '25px'}}>
							<AppFormSelectWithoutDefault
								label={ t('chooseAnAccount') }
								value={withdrawRequestForm.account}
								onChange={(event) => handleChangeWithdrawForm(event, 'account')}
							>
								{loading && (
									<Box textAlign={'center'}>
										<CircularProgress size={25}/>
									</Box>
								)}
								{!loading && accountsList ? accountsList.map(option => (
									<MenuItem
										key={option.id}
										value={option.id}
										sx={{fontFamily: 'Roboto,sans-serif'}}
										disabled={withdrawRequestForm.account === option.value}
									>
										{option.title}
									</MenuItem>
								)) : t('noData') }

							</AppFormSelectWithoutDefault>
						</AppFormControl>
					</Grid>
					<Grid item xs={12} md={5} lg={4}>
						<AppFormControl sx={{mb: '25px'}}>
							<AppFormFieldInput
								label={ t('amount') }
								type={'number'}
								value={withdrawRequestForm.amount}
								onChange={(event) => handleChangeWithdrawForm(event, 'amount')}
								InputProps={{
									inputProps: {min: 0}
								}}
								onKeyPress={(event) => {
									if (event?.key === '-' || event?.key === '+' || event?.key === 'e') {
										console.log('events key', event.key)
										event.preventDefault();
									}
								}}
							/>
						</AppFormControl>
					</Grid>
					<Grid item xs={12} md={7} lg={5} display={'flex'} justifyContent="flex-start" alignItems='center'>
						<ActionButton
							title={t('withdrawFunds')}
							sx={{p: '10px', width: {xs: '100%'}}}
							type={'submit'}
							disabled={!(withdrawRequestForm.account && withdrawRequestForm.amount)}
						/>
					</Grid>
				</Grid>
			</Box>
			<AppModal isOpen={showModal} title={modalContent.title} errorText={modalContent.text}
			          handleClose={handleCloseModal}/>
		</Box>
	)
}
export default WithdrawRequest