import React, {useEffect, useInsertionEffect, useLayoutEffect, useState} from 'react'
import {Box} from "@mui/material";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import TransactionHistory from "./Routes/TransactionHistory/TransactionHistory";
import AccountsDetails from "./Routes/AccountDetails/AccountDetails";
import Withdraw from "./Routes/Withdraw/Withdraw";
import ManageAccounts from "./Routes/ManageAccounts/ManageAccounts";
import Deposit from "./Routes/Deposit/Deposit";
import {
	ACCOUNT_DETAILS_ID,
	DEPOSIT_ID,
	MANAGE_ACCOUNTS_ID,
	TRANSACTION_HISTORY_ID, VERIFICATION_ID, WEB_TRADER_ID,
	WITHDRAW_ID
} from "../../constants/constants";
import {DashboardBox} from "./styled";
import WebTrader from "./Routes/WebTrader/WebTrader";
import {useDispatch, useSelector} from "react-redux";
import {auth, refreshToken2} from "../../store/reducers/auth/actions";
import {accountsForWT, selectAuthSucceed, isUserInvalid} from "../../store/reducers/auth/selectors";
import AppModal from "../../components/AppModal/AppModal";
import Verification from './Routes/Verification/Verification';


const Dashboard = () => {
	const [activeTab, setActiveTab] = useState(ACCOUNT_DETAILS_ID)
	const [isSidebarOpen, setIsSidebarOpen] = useState(true)
	const accounts = useSelector(accountsForWT)
	const [iframeSrc, setIframeSrc] = useState('')
	const dispatch = useDispatch()
	const param = new URLSearchParams(window.location.search)
	const crmToken = param.get('token')
	const authSucceed = useSelector(selectAuthSucceed)
	const isUserInvalidState = useSelector(isUserInvalid)

	useEffect(() => {
		const systemConfiguring = async () => {
			try {
				await dispatch(auth(crmToken || '0c8c27d55a47e4539598c95b4038a05455330b4e'))
			} catch (error) {
				console.log('auth error', error)
			}
		}

		systemConfiguring()
	}, [])

	const handleClickHamburger = () => {
		setIsSidebarOpen(!isSidebarOpen)
	}

	const handleClickTab = (id, src) => {
		setActiveTab(id)
		setIsSidebarOpen(!isSidebarOpen)
		if (id === WEB_TRADER_ID) {
			setIframeSrc(src)
		}
	}

	const activeTabDisplay = (activeTab) => {
		switch (activeTab) {
			case TRANSACTION_HISTORY_ID:
				return <TransactionHistory/>
			case ACCOUNT_DETAILS_ID:
				return <AccountsDetails/>
			case VERIFICATION_ID:
				return <Verification/>
			case WITHDRAW_ID:
				return <Withdraw/>
			case MANAGE_ACCOUNTS_ID:
				return <ManageAccounts/>
			case DEPOSIT_ID:
				return <Deposit/>
			case WEB_TRADER_ID:
				return <WebTrader src={iframeSrc}/>
			default:
				return <ManageAccounts/>
		}

	}
	return (
		<>
			<AppModal isOpen={isUserInvalidState.status} title={isUserInvalidState.message} />
			<DashboardBox sx={{height: window.innerHeight}}>
				<Header handleClickHamburger={handleClickHamburger}/>
				<Box
					display={'flex'}
					flexDirection={'row'}
					height={'calc(100% - 77px)'}
					position={'relative'}
					// overflow={'hidden'}
					mt={'77px'}
				>
					<Sidebar
						activeTabId={activeTab}
						handleClick={handleClickTab}
						isSidebarOpen={isSidebarOpen}
						accounts={accounts}
					/>
					{authSucceed &&
						<Box display={'flex'} width={{md: '75%', xs: '100%'}} overflow={'auto'} justifyContent={'center'}
							 m={'0 auto'}>
							{
								activeTabDisplay(activeTab)
							}
						</Box>}
				</Box>
			</DashboardBox>
		</>
	)
}

export default Dashboard