import React, {useState} from 'react'
import {ContentBox} from "../../../../components/ContentBox";
import WithdrawNav from "./components/WithdrawNav";
import {WITHDRAW_HISTORY_ID, WITHDRAW_REQUEST_ID} from "../../../../constants/constants";
import WithdrawRequest from "./components/WithdrawRequest";
import WithdrawHistory from "./components/WithdrawHistory";

const Withdraw = () => {
	const [activeTab, setActiveTab] = useState(WITHDRAW_REQUEST_ID)
	const handleClickTab = (id) => {
		setActiveTab(id)
	}
	const activeTabDisplay = (activeTab) => {
		switch (activeTab) {
			case WITHDRAW_REQUEST_ID:
				return <WithdrawRequest/>
			case WITHDRAW_HISTORY_ID:
				return <WithdrawHistory/>
			default:
				return <WithdrawRequest/>
		}
	}
	return(
		<ContentBox sx={{
			flexDirection: 'column', 
			overflowX: 'hidden', 
			overflowY: activeTab === WITHDRAW_HISTORY_ID ? 'hidden' : ''}}
		>
			<WithdrawNav handleClickTab={handleClickTab}/>
			{activeTabDisplay(activeTab)}
		</ContentBox>
	)
}

export default Withdraw