import React from 'react';

import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {TextFieldInput} from "./styled";

const AppFormDatePicker = (({label, date, onChange, inputProps, clearInput}) => {
	const datePickerStyles = {
		'& .PrivatePickersYear-yearButton': {
			color: 'red',
			backgroundColor: '#000000'
		}
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				label={label}
				value={date}
				onChange={onChange}
				inputFormat={'MM/DD/YYYY'}
				renderInput={(params) =>
					<TextFieldInput  {...params} error={date ==='Invalid Date'}/>
				}
				disableFuture={true}
			/>
		</LocalizationProvider>
	);
})
export default AppFormDatePicker