import React from 'react';
import {TextFieldInput} from "./styled";

const AppFormFieldInput = React.forwardRef(
	({
		 id,
		 label,
		 minRows,
		 maxRows,
		 sx,
		 type,
		 disabled,
		 placeholder,
		 autoComplete,
		 value,
		 required,
		 onChange,
		 inputProps,
		 onKeyPress,
		 onBlur
	 }, ref) => {
	return (
			<TextFieldInput
				id={id} 
				label={label} 
				variant="outlined"
				minRows={minRows}
				maxRows={maxRows}
				sx={sx}
				type={type}
				disabled={disabled}
				placeholder={placeholder}
				value={value}
				required={required}
				ref={ref}
				onChange={onChange}
				InputProps={inputProps}
				onKeyPress={onKeyPress}
				onBlur={onBlur}
			/>
	);
})

export default AppFormFieldInput