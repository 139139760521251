import {transactionHistoryApi} from "../../../api/transactionHistory";
import {withdrawApi} from "../../../api/withdraw";
import {refreshToken2} from "../auth/actions";

export const getAllTransactionsList = (params) => {
	return async dispatch => {
		try {
			const token = sessionStorage.getItem('access_token')
			const result = await transactionHistoryApi.getAllTransactions(token, params)
			dispatch({
				type: 'GET_ALL_TRANSACTIONS',
				payload: result.data.data
			})
		} catch (error) {
			console.log('error', error)
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
				return dispatch(getAllTransactionsList(params))
			}
		}
	}
}

export const getWithdrawTransactionsList = (account, period, status) => {
	return async dispatch => {
		try {
			const token = sessionStorage.getItem('access_token')
			const result = await withdrawApi.getWithdrawTransactions(token, account, period, status)
			dispatch({
				type: 'GET_WITHDRAW_TRANSACTIONS',
				payload: result.data.data
			})
		} catch (error) {
			console.log('error get withdraw transactions', error)
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
				return dispatch(getWithdrawTransactionsList(account, period, status))
			}
		}
	}
}