import React, {useEffect, useState} from 'react'
import {Box, Grid, MenuItem, Typography} from "@mui/material";
import AppFormControl from "../../../../../components/AppForm/AppFormControl";
import ActionButton from "../../../../../components/ActionButton/ActionButton";
import AppFormSelectWithoutDefault from "../../../../../components/AppForm/AppFormSelectWithoutDefault";
import {manageAccountsApi} from "../../../../../api/manageAccounts";
import {useForm} from "react-hook-form";
import AppModal from "../../../../../components/AppModal/AppModal";
import {useDispatch, useSelector} from "react-redux";
import {selectAccountTypes, selectCurrencies} from "../../../../../store/reducers/details/selectors";
import {getAccountTypesList, getCurrencies} from "../../../../../store/reducers/details/actions";
import {refreshToken2, updateAccountsForWT} from "../../../../../store/reducers/auth/actions";
import { useTranslation } from "react-i18next";


const ManageAccountsOpenAccountForm = ({closeForm}) => {
	const dispatch = useDispatch()
	const { t, i18n } = useTranslation()
	const accountTypes = useSelector(selectAccountTypes)
	const currencies = useSelector(selectCurrencies)

	const [showModal, setShowModal] = useState(false)
	const [modalContent, setModalContent] = useState({
		title: '',
		text: ''
	})
	const [openAccountForm, setOpenAccountForm] = useState({
		accountType: '',
		currencyType: ''
	})
	const {register, handleSubmit, setValue} = useForm({
		mode: 'all',
	})

	const validForm = openAccountForm.accountType && openAccountForm.currencyType
	const fetchCurrenciesTypes = async () => {
		if (currencies.length) return
		try {
			await dispatch(getCurrencies())
		} catch (error) {
			console.log('error', error)
			
		}
	}

	const fetchAccountTypes = async () => {
		if (accountTypes.length) return
		try {
			await dispatch(getAccountTypesList())
		} catch (error) {
			console.log('error', error)
		}
	}

	const handleChangeForm = (event, inputType) => {
		const formCopy = {...openAccountForm}
		formCopy[inputType] = event.target.value
		setOpenAccountForm(formCopy)
		setValue(inputType, event.target.value)
	}

	const createAccount = async (accountType, currencyType) => {
		try {
			const token = sessionStorage.getItem('access_token')
			await manageAccountsApi.createClientsTradingAccount(token, accountType, currencyType)
				.then((res) => {
						dispatch(updateAccountsForWT(res.data.account))
						setShowModal(true)
						setModalContent({title: t('success'), text: t('accountSuccessfullyCreated')})
					}
				)
		} catch (error) {
			console.log('error creating account', error)
			if (error.response.status === 401) {
				await dispatch(refreshToken2())
				return createAccount(accountType, currencyType)
			} else {
				setShowModal(true)
				setModalContent({title: t('error'), text: error.response.data.details || error.response.data.detail})
			}
		}
	}
	const onSubmit = async (data) => {
		const {accountType, currencyType} = data
		await createAccount(accountType, currencyType)
	}

	const handleCloseModal = () => {
		setShowModal(false)
		closeForm()
	}

	useEffect(() => {
		fetchCurrenciesTypes()
			.then(() => fetchAccountTypes())
		
	}, [])

	return (
		<Box>
			<Typography variant={'subtitle2'} mb={'40px'}>
				{t('openAccount')}
			</Typography>
			<Box
				component={'form'}
				onSubmit={handleSubmit(onSubmit)}
			>
				<Grid container>
					<Grid item xs={12} md={5.5}>
						<AppFormControl sx={{mb: '25px'}}>
							<AppFormSelectWithoutDefault
								label={ t('accountType') }
								{...register('accountType', {required: 'need to select'})}
								onChange={(event) => handleChangeForm(event, 'accountType')}
								value={openAccountForm.accountType}
							>
								{
									accountTypes ?
										accountTypes.map(item => (
											<MenuItem
												key={item.account_type_code}
												value={item.account_type_code}
												sx={{fontFamily: 'Roboto,sans-serif'}}
											>

												{item.account_type_name}
											</MenuItem>
										)) : ''
								}
							</AppFormSelectWithoutDefault>
						</AppFormControl>
						<AppFormControl sx={{mb: '25px'}}>
							<AppFormSelectWithoutDefault
								label={`${t('currency')}*`}
								{...register('currencyType', {required: true})}
								onChange={(event) => handleChangeForm(event, 'currencyType')}
								value={openAccountForm.currencyType}
							>
								{
									currencies ?
										currencies.map(item => (
											<MenuItem
												key={item.currency_code}
												value={item.currency_code}
												sx={{fontFamily: 'Roboto,sans-serif'}}
											>

												{item.currency_name}
											</MenuItem>
										)) : ''
								}
							</AppFormSelectWithoutDefault>
						</AppFormControl>
						<Box display={'flex'} gap={'8px'}>
							<ActionButton
								title={t('cancel')}
								color={'primary'}
								onClick={() => closeForm()}
							/>
							<ActionButton
								title={t('createAccount')}
								color={'secondary'}
								sx={{p: '11px 9px'}}
								disabled={!validForm}
								type={'submit'}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<AppModal isOpen={showModal} title={modalContent.title} errorText={modalContent.text}
			          handleClose={handleCloseModal}/>
		</Box>
	)
}

export default ManageAccountsOpenAccountForm