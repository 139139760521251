import React from 'react'

import {HamburgerButton, HeaderBox} from './styled'
import {Box, CircularProgress, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import ActionButton from "../../../components/ActionButton/ActionButton";
import {useDispatch, useSelector} from "react-redux";
import {isLogoutLoading} from "../../../store/reducers/auth/selectors";
import {logout} from "../../../store/reducers/auth/actions";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from './LanguageSwitcher';

const Header = ({ handleClickHamburger}) => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const loading = useSelector(isLogoutLoading)
	
	return(
		<HeaderBox>
			<HamburgerButton onClick={() => handleClickHamburger()}>
				<MenuIcon/>
			</HamburgerButton>
			<Typography variant='h1' letterSpacing={'0.43px'}>
				{process.env.REACT_APP_TITLE || 'Client area'}
			</Typography>
			<LanguageSwitcher />
			<ActionButton
				title={ t('logout') }
				sx={{width: 'max-content'}}
				disabled={loading}
				onClick={() => dispatch(logout(true))}
			/>
		</HeaderBox>
	)
}

export default Header