import React from 'react'
import {OutlinedInput, Select} from "@mui/material";
import {SelectInputLabel} from "./styled";
import {customStyles} from "./sharedCustomStyles";

const AppFormSelectWithDefault = React.forwardRef(({label, id, onChange, value, options, disabled, children }, ref) => {


	return (
		<>
			<SelectInputLabel id="demo-simple-select-label" shrink>{label}</SelectInputLabel>
			<Select
				labelId="demo-simple-select-label"
				id={id}
				value={value}
				label={label}
				onChange={onChange}
				ref={ref}
				displayEmpty
				sx={customStyles}
				input={<OutlinedInput notched label={label} />}
			>
				{children}
			</Select>
		</>
	)
})

export default AppFormSelectWithDefault